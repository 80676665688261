import { FC } from "react"
import { Link } from "react-router-dom"
import { privateRedirectPath } from "../../constants/routes"
import { sidebar } from "../../pages/wrappers/Private"
import Icon from "../Icon"
import Row from "../Row"
import HeaderUser from "./HeaderUser"

const Header: FC = () => {
  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__content">

          <div className="header__left">
            <button
              className="header__static"
              aria-controls="sidebar"
              onClick={(): void => {
                sidebar.current?.toggle()
              }}
            >
              {/* <span className="sr-only">Open sidebar</span> */}
              <Link to={privateRedirectPath}>
                <Row>
                  <Icon name="logo" size={144}/>
                </Row>
              </Link>
              {/* <svg className="header__toggle-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg> */}
            </button>
          </div>

          <div className="header__right">
            <HeaderUser />
          </div>

        </div>
      </div>
    </header>
  )
}

export default Header
