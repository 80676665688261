import { FC } from "react"
import { Link } from "react-router-dom"
import Button from "../components/Button"
import { privateRedirectPath } from "../constants/routes"

const NotFound: FC = () => {
  return (
    <div className="container">
      <h1>Page not found</h1>

      <Link to={privateRedirectPath}>
        <Button>Homepage</Button>
      </Link>
    </div>
  )
}

export default NotFound
