import { FC, ReactNode, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import Button from "./Button"
import useAuth from "../hooks/useAuth"
import useError from "../hooks/useError"
import { Ticket, TicketsService } from "../ticketsSdk"
import Table from "./Table"
import classNames from "classnames"
import Row from "./Row"
import { resolveUserName } from "../helpers/session"
import Spinner from "./Spinner"
import StatusTag from "./StatusTag"
import { shorten } from "../helpers/text"
import PriorityTag from "./PriorityTag"

const LIMIT = 20

type TicketsTableProps = {
  unresolved: boolean
}

const TicketsTable: FC<TicketsTableProps> = ({ unresolved }) => {
  const { user, customer, sameCustomerUsers } = useAuth()
  const [loading, setLoading] = useState<boolean>(true)
  const [updating, setUpdating] = useState<boolean>(false)
  const [resolvedTickets, setResolvedTickets] = useState<Ticket[]>([])
  const [unresolvedTickets, setUnresolvedTickets] = useState<Ticket[]>([])
  const [resolvedNextContinuationToken, setResolvedNextContinuationToken] = useState<string | undefined>(undefined)
  const [unresolvedNextContinuationToken, setUnresolvedNextContinuationToken] = useState<string | undefined>(undefined)
  const ticketsLoadInterval = useRef<number | null>(null)
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<number>(0)
  const { handleError } = useError()

  const loadTickets = async(unresolved: boolean, resetPreviousTickets: boolean = false): Promise<void> => {
    const currentTickets = unresolved ? unresolvedTickets : resolvedTickets
    const continuationToken = unresolved ? unresolvedNextContinuationToken : resolvedNextContinuationToken

    if (!resetPreviousTickets && currentTickets.length > 0 && !continuationToken) {
      return
    }

    if (!resetPreviousTickets) {
      if (currentTickets.length === 0) {
        setLoading(true)
      } else {
        setUpdating(true)
      }
    }

    try {
      const { data, continuationToken: nextContinuationToken } = await TicketsService.listTickets({
        limit: LIMIT,
        unresolved,
        continuationToken: resetPreviousTickets ? undefined : continuationToken
      })
      const setContinuationToken = unresolved ? setUnresolvedNextContinuationToken : setResolvedNextContinuationToken
      const setTickets = unresolved ? setUnresolvedTickets : setResolvedTickets

      setContinuationToken(nextContinuationToken)
      if (resetPreviousTickets) {
        setTickets([...data])
      } else {
        setTickets((prevTickets) => [
          ...prevTickets, ...data
        ])
      }
    } catch (err) {
      handleError(err)
    }
    setUpdating(false)
    setLoading(false)
  }

  useEffect(() => {
    void (async(): Promise<void> => {
      if (user) {
        const currentTickets = unresolved ? unresolvedTickets : resolvedTickets
        if (currentTickets.length === 0) {
          await loadTickets(unresolved)
        }
      }
    })()
  }, [unresolved])

  useEffect(() => {
    if (lastUpdateTimestamp > 0) {
      void (async(): Promise<void> => {
        const allTickets = [...resolvedTickets, ...unresolvedTickets]
        if (allTickets.every(t => lastUpdateTimestamp > t.lastUpdateTimestamp)) {
          await Promise.all([loadTickets(true, true), loadTickets(false, true)])
        }
      })()
    }
  }, [lastUpdateTimestamp])

  const clearTicketsLoadInterval = (): void => {
    if (ticketsLoadInterval.current) {
      clearInterval(ticketsLoadInterval.current)
    }
  }

  const setTicketsLoadInterval = (): void => {
    clearTicketsLoadInterval()
    const intervalId = setInterval(async() => {
      try {
        const { lastUpdateTimestamp: newLastUpdateTimestamp } = await TicketsService.getLastUpdateTimestamp({
          customerId: customer?.customerId
        })
        setLastUpdateTimestamp(newLastUpdateTimestamp)
      } catch (err) {
        handleError(err)
      }
    }, 1000 * 60) // 60 sec

    // eslint-disable-next-line @typescript-eslint/ban-types
    ticketsLoadInterval.current = intervalId as unknown as number
  }

  useEffect(() => {
    setTicketsLoadInterval()
    return clearTicketsLoadInterval
  }, [])


  const tickets = unresolved ? unresolvedTickets : resolvedTickets
  const nextContinuationToken = unresolved ? unresolvedNextContinuationToken : resolvedNextContinuationToken
  return (
    <>
      { loading ?
        <Row justify="center" className="mt-5">
          <Spinner/>
        </Row>
        :
        tickets.length > 0 ?
          <div className="w-full overflow-x-auto">
            <Table
              className={classNames(updating && "opacity-50 pointer-events-none", "table")}
              headings={["Titolo", "Aperto da", "Stato", "Priorità", "Data di creazione"]}
              leftColumns={["Titolo", "Aperto da", "Stato", "Priorità", "Data di creazione"]}
              rows={tickets.map(({
                ticketId,
                author,
                title,
                status,
                creationTimestamp,
                priority
              }): { id: string, children: ReactNode[] } => {
                const creationDateTime = new Date(creationTimestamp).toLocaleString()
                const authorName = resolveUserName(author, sameCustomerUsers)
                return {
                  id: ticketId,
                  children: [
                    <Link to={`/tickets/${ticketId}`}>
                      <div className="underline text-indigo-600 max-w-80">{shorten(title, 80)}</div>
                    </Link>,
                    <span>{authorName}</span>,
                    <StatusTag status={status}/>,
                    <PriorityTag priority={priority} hasPrefix={false}/>,
                    <span>{creationDateTime}</span>
                  ]
                }
              })}
            />
            { nextContinuationToken &&
              <Row justify="center" className="mt-5">
                <Button onClick={(): void => void loadTickets(unresolved)} disabled={updating}>Carica altro</Button>
              </Row>
            }
          </div>
          :
          <Row justify="center" className="mt-5">
            <h1>Nessun ticket presente</h1>
          </Row>
      }
    </>
  )
}

export default TicketsTable
