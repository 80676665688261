/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeCustomerUserPasswordRequestSchema } from '../models/ChangeCustomerUserPasswordRequestSchema';
import type { ConfirmCustomerUserMFASecret200ResponseSchema } from '../models/ConfirmCustomerUserMFASecret200ResponseSchema';
import type { ConfirmCustomerUserMFASecretRequestSchema } from '../models/ConfirmCustomerUserMFASecretRequestSchema';
import type { CreateCustomerUser200ResponseSchema } from '../models/CreateCustomerUser200ResponseSchema';
import type { CreateCustomerUserRequestSchema } from '../models/CreateCustomerUserRequestSchema';
import type { CustomerLogin200ResponseSchema } from '../models/CustomerLogin200ResponseSchema';
import type { CustomerLoginRequestSchema } from '../models/CustomerLoginRequestSchema';
import type { DeleteCustomerUserRequestSchema } from '../models/DeleteCustomerUserRequestSchema';
import type { GenerateCustomerUserMFASecret200ResponseSchema } from '../models/GenerateCustomerUserMFASecret200ResponseSchema';
import type { GenerateCustomerUserMFASecretRequestSchema } from '../models/GenerateCustomerUserMFASecretRequestSchema';
import type { GetCustomerUser200ResponseSchema } from '../models/GetCustomerUser200ResponseSchema';
import type { GetCustomerUserRequestSchema } from '../models/GetCustomerUserRequestSchema';
import type { ListCustomerUsers200ResponseSchema } from '../models/ListCustomerUsers200ResponseSchema';
import type { ListCustomerUsersRequestSchema } from '../models/ListCustomerUsersRequestSchema';
import type { OkSchema } from '../models/OkSchema';
import type { RefreshCustomerUserSession200ResponseSchema } from '../models/RefreshCustomerUserSession200ResponseSchema';
import type { RequestResetCustomerUserPasswordRequestSchema } from '../models/RequestResetCustomerUserPasswordRequestSchema';
import type { ResetCustomerUserPasswordRequestSchema } from '../models/ResetCustomerUserPasswordRequestSchema';
import type { UpdateCustomerUserRequestSchema } from '../models/UpdateCustomerUserRequestSchema';
import type { VerifyCustomerUserMFAToken200ResponseSchema } from '../models/VerifyCustomerUserMFAToken200ResponseSchema';
import type { VerifyCustomerUserMFATokenRequestSchema } from '../models/VerifyCustomerUserMFATokenRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerUsersService {

    /**
     * customerLogin
     * Returns a valid session given user credentials (if user has MFA set, session will be valid only for MFA token verification)
     * @param requestBody
     * @returns CustomerLogin200ResponseSchema CustomerLogin200Response
     * @throws ApiError
     */
    public static customerLogin(
        requestBody?: CustomerLoginRequestSchema,
    ): CancelablePromise<CustomerLogin200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/customerLogin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * customerLogout
     * Customer logout
     * @returns OkSchema CustomerLogout200Response
     * @throws ApiError
     */
    public static customerLogout(): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/customerLogout',
        });
    }

    /**
     * refreshCustomerUserSession
     * Refresh customer user session
     * @returns RefreshCustomerUserSession200ResponseSchema RefreshCustomerUserSession200Response
     * @throws ApiError
     */
    public static refreshCustomerUserSession(): CancelablePromise<RefreshCustomerUserSession200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/refreshCustomerUserSession',
        });
    }

    /**
     * generateCustomerUserMFASecret
     * Generate a new MFA secret
     * @param requestBody
     * @returns GenerateCustomerUserMFASecret200ResponseSchema GenerateCustomerUserMFASecret200Response
     * @throws ApiError
     */
    public static generateCustomerUserMfaSecret(
        requestBody?: GenerateCustomerUserMFASecretRequestSchema,
    ): CancelablePromise<GenerateCustomerUserMFASecret200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/generateCustomerUserMFASecret',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * confirmCustomerUserMFASecret
     * Confirm a pending MFA secret
     * @param requestBody
     * @returns ConfirmCustomerUserMFASecret200ResponseSchema ConfirmCustomerUserMFASecret200Response
     * @throws ApiError
     */
    public static confirmCustomerUserMfaSecret(
        requestBody?: ConfirmCustomerUserMFASecretRequestSchema,
    ): CancelablePromise<ConfirmCustomerUserMFASecret200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/confirmCustomerUserMFASecret',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * verifyCustomerUserMFAToken
     * Verify MFA token for users with an existing secret
     * @param requestBody
     * @returns VerifyCustomerUserMFAToken200ResponseSchema VerifyCustomerUserMFAToken200Response
     * @throws ApiError
     */
    public static verifyCustomerUserMfaToken(
        requestBody?: VerifyCustomerUserMFATokenRequestSchema,
    ): CancelablePromise<VerifyCustomerUserMFAToken200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/verifyCustomerUserMFAToken',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * getCustomerUser
     * Returns customer user info
     * @param requestBody
     * @returns GetCustomerUser200ResponseSchema GetCustomerUser200Response
     * @throws ApiError
     */
    public static getCustomerUser(
        requestBody?: GetCustomerUserRequestSchema,
    ): CancelablePromise<GetCustomerUser200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/getCustomerUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * listCustomerUsers
     * Retrieve all customer users
     * @param requestBody
     * @returns ListCustomerUsers200ResponseSchema ListCustomerUsers200Response
     * @throws ApiError
     */
    public static listCustomerUsers(
        requestBody?: ListCustomerUsersRequestSchema,
    ): CancelablePromise<ListCustomerUsers200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/listCustomerUsers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * createCustomerUser
     * Create new customer user
     * @param requestBody
     * @returns CreateCustomerUser200ResponseSchema CreateCustomerUser200Response
     * @throws ApiError
     */
    public static createCustomerUser(
        requestBody?: CreateCustomerUserRequestSchema,
    ): CancelablePromise<CreateCustomerUser200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/createCustomerUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * deleteCustomerUser
     * Delete a customer user
     * @param requestBody
     * @returns OkSchema DeleteCustomerUser200Response
     * @throws ApiError
     */
    public static deleteCustomerUser(
        requestBody?: DeleteCustomerUserRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/deleteCustomerUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * updateCustomerUser
     * Updates an existing customer user
     * @param requestBody
     * @returns OkSchema UpdateCustomerUser200Response
     * @throws ApiError
     */
    public static updateCustomerUser(
        requestBody?: UpdateCustomerUserRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/updateCustomerUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * changeCustomerUserPassword
     * Change customer user password
     * @param requestBody
     * @returns OkSchema ChangeCustomerUserPassword200Response
     * @throws ApiError
     */
    public static changeCustomerUserPassword(
        requestBody?: ChangeCustomerUserPasswordRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/changeCustomerUserPassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * requestResetCustomerUserPassword
     * Request mail to reset password
     * @param requestBody
     * @returns OkSchema RequestResetCustomerUserPassword200Response
     * @throws ApiError
     */
    public static requestResetCustomerUserPassword(
        requestBody?: RequestResetCustomerUserPasswordRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/requestResetCustomerUserPassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * resetCustomerUserPassword
     * Reset password of a customer user
     * @param requestBody
     * @returns OkSchema ResetCustomerUserPassword200Response
     * @throws ApiError
     */
    public static resetCustomerUserPassword(
        requestBody?: ResetCustomerUserPasswordRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customer-users/resetCustomerUserPassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
