import classNames from "classnames"
import { FC, HTMLProps, ReactNode } from "react"

export interface InputProps extends Omit<HTMLProps<HTMLTextAreaElement>, "label" | "onChange"> {
  onChange?(value: string): void
  label?: ReactNode
  labelProps?: HTMLProps<HTMLLabelElement>
  error?: ReactNode
  errorProps?: HTMLProps<HTMLDivElement>
  textareaProps?: HTMLProps<HTMLTextAreaElement>
  resizable?: boolean
}

const Textarea: FC<InputProps> = ({
  className,
  label,
  labelProps,
  error,
  textareaProps,
  errorProps,
  onChange,
  resizable = true,
  ...props
}) => {
  return (
    <div className={className}>
      {label
        ?
        <label
          {...labelProps}
          className={classNames("input__label", labelProps?.className)}
        >{label}</label>
        : null
      }

      <textarea
        className={classNames(
          "input input--textarea",
          !!error && "input--error",
          !resizable && "input--textarea-fixed",
          textareaProps?.className
        )}
        onChange={(e): void => onChange?.(e.target.value)}
        {...props}
      />

      {error
        ?
        <div
          {...errorProps}
          className={classNames("input__error", errorProps?.className)}
        >
          {error}
        </div>
        : null
      }
    </div>
  )
}

export default Textarea
