import { FC } from "react"
import { Navigate } from "react-router-dom"
import { privateRedirectPath } from "../constants/routes"

const HomeRedirect: FC = () => {
  return (
    <Navigate to={privateRedirectPath}/>
  )
}

export default HomeRedirect
