import { FC } from "react"
import Icon from "./Icon"
import Row from "./Row"

type Props = {
  filesToUpload: {[fileName: string]: File}
  setFilesToUpload(files: {[fileName: string]: File}): void
}

const AttachmentsInput: FC<Props> = ({
  filesToUpload,
  setFilesToUpload
}) => {
  return (<>
    <label className="mr-3 cursor-pointer">
      <Row>
        <div className="mr-3">
        </div>
        <Icon name="paperclip" size={25} className="hover:stroke-indigo-500"/>
        <input
          className="hidden"
          type="file"
          multiple
          onChange={async(e): Promise<void> => {
            const files = e.target.files
            const filesDict: {[fileName: string]: File} = { ...filesToUpload }
            if (files) {
              for (let i = 0; i < files.length; i++) {
                let file = files.item(i)
                if (file) {
                  if (filesDict[file.name]) {
                    // handle duplicate
                    const oldName = file.name
                    let newName = file.name
                    let seriesNumber = 1
                    while (filesDict[newName] && seriesNumber < 10000) {
                      newName = `(${seriesNumber}) ${oldName}`
                      seriesNumber++
                    }
                    file = new File([file], `${newName}`, { type: file.type })
                  }
                  filesDict[file.name] = file
                }
              }
            }
            e.target.value = ""
            setFilesToUpload(filesDict)
          }}
        />
      </Row>
    </label>
  </>)
}


export default AttachmentsInput

