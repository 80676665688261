import { useRef } from "react"
import { useEffect } from "react"

type UseAutoCloseParams = {
  condition: boolean
  action(): void
  excludeTargets: (HTMLElement | null)[]
}

const useAutoClose = ({
  action,
  condition,
  excludeTargets
}: UseAutoCloseParams): void => {
  const listenersAdded = useRef<boolean>(false)

  useEffect(() => {
    if (condition) {
      /*
        When adding the listeners to the body, onClick gets
        triggered simultaneously to the click that changes the
        hook condition, so if there's some node that is not
        included among the targets which changes open condition,
        onClick gets called and re-changes instantly the state,
        so we need to block the effect on first click through
        the `listenersAdded` ref to avoid rerenders
      */
      const onClick = (e: MouseEvent): void => {
        if (listenersAdded.current) {
          const target = e.target as Node
          if (!excludeTargets.some((el: HTMLElement | null) => el?.contains(target))) {
            action()
          }
        }
        listenersAdded.current = true
      }
      const onKeyDown = ({ key }: KeyboardEvent): void => {
        if (key === "Escape") {
          action()
        }
        listenersAdded.current = true
      }

      document.addEventListener("click", onClick)
      document.addEventListener("keydown", onKeyDown)

      return (): void => {
        document.removeEventListener("click", onClick)
        document.removeEventListener("keydown", onKeyDown)

        listenersAdded.current = false
      }
    }
  }, [condition])
}

export default useAutoClose
