import { SetStateAction, useContext } from "react"
import { AuthContext, AuthContextType } from "../context/AuthContext"
import { CustomerUser, Customer as PrincipalsCustomer } from "../principalsSdk"
import { Customer } from "../ticketsSdk"

type UseAuth = {
  logout(): Promise<void>
  setSessionRefreshInterval(): void
  startSession(session: string): Promise<void>
  user: CustomerUser | null | undefined
  setUser(customerUser: SetStateAction<CustomerUser | null | undefined>): void
  customer: Customer | null
  setCustomer(customer: SetStateAction<Customer | null>): void
  sameCustomerUsers: {[key: string]: CustomerUser}
  principalsCustomer: PrincipalsCustomer | null
}

const useAuth = (): UseAuth => {
  const {  logout, setSessionRefreshInterval, startSession, user, setUser, customer, setCustomer, sameCustomerUsers, principalsCustomer } = useContext<AuthContextType>(AuthContext)

  return {
    logout,
    setSessionRefreshInterval,
    startSession,
    user,
    setUser,
    customer,
    setCustomer,
    sameCustomerUsers,
    principalsCustomer
  }
}

export default useAuth
