import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Button from "../components/Button"
import Card from "../components/Card"
import Col from "../components/Col"
import Input from "../components/Input"
import InputPassword from "../components/InputPassword"
import Row from "../components/Row"
import useError from "../hooks/useError"
import { CustomerLoginRequestSchema, CustomerUsersService } from "../principalsSdk"
import useAuth from "../hooks/useAuth"
import { Link, useNavigate } from "react-router-dom"
import { getSessionData, setSession } from "../helpers/session"
import { USER_ROLES } from "../context/AuthContext"


const Login: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { logout, startSession } = useAuth()
  const { handleError } = useError()
  const { control, formState, handleSubmit } = useForm<CustomerLoginRequestSchema>()

  const onSubmit = async(data: CustomerLoginRequestSchema): Promise<void> => {
    setLoading(true)

    try {
      const { session } = await CustomerUsersService.customerLogin(data)

      const sessionData = getSessionData(session)
      if (sessionData.roles.includes(USER_ROLES.PENDING_MFA)) {
        setSession(session)
        navigate("/mfa")
      } else {
        await startSession(session)
      }
    } catch (err) {
      handleError(err)
      await logout()
    }
    setLoading(false)
  }

  return (
    <div className={"container h-full items-center grid grid-cols-12 px-4 sm:px-6 lg:px-8"}>
      <Col lg={4} className="lg:col-start-5">
        <Card>
          <h1 className="text-xl font-bold text-center">Login</h1>

          <p className="mt-1 mb-4 text-sm text-gray-400 text-center">Inserisci le credenziali per accedere alla dashboard</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Campo obbligatorio"
              }}
              defaultValue=""
              render={({ field: { onChange, value } }): ReactElement => (
                <Input
                  className="mb-2"
                  label="Email"
                  type="email"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.email?.message}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                required: "Campo obbligatorio"
              }}
              defaultValue=""
              render={({ field: { onChange, value } }): ReactElement => (
                <InputPassword
                  label="Password"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.password?.message}
                />
              )}
            />
            <Row className="mt-2">
              <Link to={"/reset-password"}>
                <span className="underline text-indigo-600 text-sm">Password dimenticata?</span>
              </Link>
            </Row>

            <Row className="mt-4" justify="center">
              <Button type="submit" disabled={loading}>Login</Button>
            </Row>
          </form>
        </Card>
      </Col>
    </div>
  )
}

export default Login
