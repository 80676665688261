import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { shorten } from "../helpers/text"
import useAuth from "../hooks/useAuth"
import useWindowWidth from "../hooks/useWindowWidth"
import { CustomerUser } from "../principalsSdk"
import Bubble from "./Bubble"
import InputBubble, { Hint } from "./InputBubble"
import Row from "./Row"

type Props = {
  cc: {[key: string]: CustomerUser | string }
  setCc: Dispatch<SetStateAction<{[key: string]: CustomerUser | string}>>
  ticketAuthorId: string
}

type AddMessageForm = {
  body: string
}

const CcForm: FC<Props> = ({ cc, setCc, ticketAuthorId }) => {
  const { user, sameCustomerUsers } = useAuth()
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [results, setResults] = useState<Hint[]>([])
  const { control, formState, reset } = useForm<AddMessageForm>()
  const { before } = useWindowWidth()

  useEffect(() => {
    if (user) {
      const remainingSameCustomerUsers  = { ...sameCustomerUsers }
      Object.keys(cc).forEach(id => delete remainingSameCustomerUsers[id])
      delete remainingSameCustomerUsers[user.userId]
      delete remainingSameCustomerUsers[ticketAuthorId]
      let newResults = Object.values(remainingSameCustomerUsers)
      if (searchQuery) {
        newResults = newResults.filter(r => {
          const word = searchQuery.toUpperCase().trim()
          return r.username.toUpperCase().includes(word)
          //    || r.email.toUpperCase().includes(word)
        })
      }
      setResults(newResults.map((r): Hint => {
        return {
          id: r.userId,
          value: r.username
        }
      }))
    }
  }, [searchQuery, cc])

  const addAction = (id: string, isEmail: boolean = false): void => {
    const u = isEmail
      ? id
      : sameCustomerUsers[id]
    if (u) {
      setSearchQuery("")
      setCc(cc => {
        cc[id] ??= u
        return { ...cc }
      })
      reset()
    }

  }

  const removeAction = (userId: string): void => {
    if (cc[userId]) {
      setCc(old => {
        const newCc = { ...old }
        delete newCc[userId]
        return newCc
      })
    }
  }

  const remainingCustomerUsers = Object.keys(sameCustomerUsers).filter(userId => userId !== user?.userId && userId !== ticketAuthorId).length - Object.keys(cc).length  // current user and ticket author can't be in cc list

  return (
    <Row className="flex-wrap">
      {Object.values(cc).map(u => {
        const isEmail = typeof u === "string"
        const [id, label] = isEmail
          ? [u, u]
          : [u.userId, u.username]
        return (
          <Bubble
            onClose={(): void => removeAction(id)}
            key={id}
            className="mr-2 mb-2"
            label={before("sm") ? shorten(label, 20) : label}
          />
        )
      }
      )}
      <Controller
        name="body"
        control={control}
        rules={{
          required: true
        }}
        defaultValue=""
        render={({ field: { onChange, value } }): ReactElement => (
          <div className="relative">
            <InputBubble
              className="mb-2"
              aria-multiline={true}
              placeholder="Aggiungi persone..."
              value={value}
              onChange={(newVal): void => {
                // TODO: add debounce
                const clearedVal = newVal
                onChange(clearedVal)
                setSearchQuery(clearedVal)
              }}
              showNoResultsMessage={remainingCustomerUsers > 0}
              selectHint={addAction}
              error={formState.errors.body?.message}
              hints={results}
            />
          </div>
        )}
      />
    </Row>
  )
}

export default CcForm
