/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomer200ResponseSchema } from '../models/CreateCustomer200ResponseSchema';
import type { CreateCustomerRequestSchema } from '../models/CreateCustomerRequestSchema';
import type { DeleteCustomerRequestSchema } from '../models/DeleteCustomerRequestSchema';
import type { GetCustomer200ResponseSchema } from '../models/GetCustomer200ResponseSchema';
import type { GetCustomerRequestSchema } from '../models/GetCustomerRequestSchema';
import type { ListCustomers200ResponseSchema } from '../models/ListCustomers200ResponseSchema';
import type { ListCustomersRequestSchema } from '../models/ListCustomersRequestSchema';
import type { OkSchema } from '../models/OkSchema';
import type { UpdateCustomerRequestSchema } from '../models/UpdateCustomerRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomersService {

    /**
     * listCustomers
     * Retrieve all customers available
     * @param requestBody
     * @returns ListCustomers200ResponseSchema ListCustomers200Response
     * @throws ApiError
     */
    public static listCustomers(
        requestBody?: ListCustomersRequestSchema,
    ): CancelablePromise<ListCustomers200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/listCustomers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * getCustomer
     * Get customer data
     * @param requestBody
     * @returns GetCustomer200ResponseSchema GetCustomer200Response
     * @throws ApiError
     */
    public static getCustomer(
        requestBody?: GetCustomerRequestSchema,
    ): CancelablePromise<GetCustomer200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/getCustomer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * createCustomer
     * Create a customer
     * @param requestBody
     * @returns CreateCustomer200ResponseSchema CreateCustomer200Response
     * @throws ApiError
     */
    public static createCustomer(
        requestBody?: CreateCustomerRequestSchema,
    ): CancelablePromise<CreateCustomer200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/createCustomer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * deleteCustomer
     * Delete a customer
     * @param requestBody
     * @returns OkSchema DeleteCustomer200Response
     * @throws ApiError
     */
    public static deleteCustomer(
        requestBody?: DeleteCustomerRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/deleteCustomer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * updateCustomer
     * Update a customer in the database
     * @param requestBody
     * @returns OkSchema UpdateCustomer200Response
     * @throws ApiError
     */
    public static updateCustomer(
        requestBody?: UpdateCustomerRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/updateCustomer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
