import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { UseFormHandleSubmit } from "react-hook-form"
import Button from "./Button"
import Icon from "./Icon"
import Input, { InputProps } from "./Input"
import Row from "./Row"
import Spinner from "./Spinner"

type Omitted = "type" | "suffix"
interface Props extends Omit<InputProps, Omitted> {
  loading: boolean
}

const InputMfaCode: FC<Props> = ({
  onChange,
  labelProps,
  label,
  loading,
  ...inputProps
}) => {

  return (
    <div className="w-full">
      <Row>
        <label
          {...labelProps}
          className={classNames("input__label", labelProps?.className)}
        >{label}</label>
      </Row>
      <Row>
        <Input
          onChange={async(newVal): Promise<void> => {
            if (onChange) {
              const clearedVal = newVal.replace(/[^0-9]/g, "").slice(0, 6)
              onChange(clearedVal)
            }
          }}
          {...inputProps}
        />
        <Button type={loading ? "button" : "submit"} className="ml-3 self-start h-10 min-w-16">
          {loading
            ? <Spinner color="white" size={18} />
            : "Invia"}
        </Button>
      </Row>
    </div>
  )
}

export default InputMfaCode
