import { createContext, ReactElement, ReactNode, SetStateAction, useEffect, useRef, useState } from "react"
import { Customer as PrincipalsCustomer, CustomerUser, CustomerUsersService, OpenAPI as principalsConfig } from "../principalsSdk"
import { Customer, OpenAPI as ticketsConfig } from "../ticketsSdk"
import { getPrincipalsCustomer, getTicketsCustomer, getSessionData, refreshSession, setSession, getUserFromSessionData } from "../helpers/session"
import { toast } from "react-toastify"
import useError from "../hooks/useError"

export enum USER_ROLES {
  AUTHENTICATED_WITH_MFA = "AUTHENTICATED_WITH_MFA",
  AUTHENTICATED_NO_MFA = "AUTHENTICATED_NO_MFA",
  AUTHENTICATED = "(AUTHENTICATED_NO_MFA || AUTHENTICATED_WITH_MFA)",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  PENDING_MFA = "PENDING_MFA",
  PENDING_PASSWORD_RESET = "PENDING_PASSWORD_RESET",
  ADMIN = "ADMIN",
  SELF = "SELF",
  SELF_CUSTOMER = "SELF_CUSTOMER",
  STAFF = "STAFF",
  CUSTOMER = "CUSTOMER"
}

export type AuthContextType = {
  logout(): Promise<void>
  setSessionRefreshInterval(): void
  startSession(session: string): Promise<void>
  user: CustomerUser | null | undefined
  setUser(customerUser: SetStateAction<CustomerUser | null | undefined>): void
  customer: Customer | null
  setCustomer(customer: SetStateAction<Customer | null>): void
  sameCustomerUsers: {[key: string]: CustomerUser}
  principalsCustomer: PrincipalsCustomer | null
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export default function AuthProvider({ children }: { children: ReactNode }): ReactElement {
  const [user, setUser] = useState<CustomerUser | null | undefined>()
  const [customer, setCustomer] = useState<Customer | null>(null)
  const sessionInterval = useRef<number | null>(null)
  const [sameCustomerUsers, setSameCustomerUsers] = useState<{[key: string]: CustomerUser}>({})
  const [principalsCustomer, setPrincipalsCustomer] = useState<PrincipalsCustomer | null>(null)
  const { handleError } = useError()

  const clearSessionInterval = (): void => {
    if (sessionInterval.current) {
      clearInterval(sessionInterval.current)
    }
  }

  const setSessionRefreshInterval = (): void => {
    clearSessionInterval()

    const intervalId = setInterval(async() => {
      const newSessionToken = await refreshSession()
      setSession(newSessionToken)
    }, 1000 * 60 * 10) // 10 min

    // eslint-disable-next-line @typescript-eslint/ban-types
    sessionInterval.current = intervalId as unknown as number
  }

  const clearLocalData = (): void => {
    clearSessionInterval()

    localStorage.removeItem("session_token")

    principalsConfig.TOKEN = undefined
    ticketsConfig.TOKEN = undefined

    setUser(null)
    setCustomer(null)
    setSameCustomerUsers({})
    setPrincipalsCustomer(null)
  }

  const logout = async(): Promise<void> => {
    if (user) {
      try {
        await CustomerUsersService.customerLogout()
        clearLocalData()
      } catch (err) {
        handleError(err)
      }
    } else {
      clearLocalData()
    }
  }

  const startSession = async(session: string): Promise<void> => {
    setSession(session)
    const sessionData = getSessionData(session)
    const customerUser = await getUserFromSessionData(sessionData)
    if (customerUser.active) {
      const getTicketCustomerPromise = getTicketsCustomer(customerUser.customerId)
      const getPrincipalsCustomerPromise = getPrincipalsCustomer(customerUser.customerId)
      const loadSameCustomerUsersPromise = loadSameCustomerUsers(customerUser.customerId)
      const [customer, principalsCustomer] = await Promise.all([getTicketCustomerPromise, getPrincipalsCustomerPromise, loadSameCustomerUsersPromise])
      setUser(customerUser)
      setCustomer(customer)
      setPrincipalsCustomer(principalsCustomer)
      setSessionRefreshInterval()
    } else {
      toast.error("Errore, utente non attivo")
      await logout()
    }
  }


  const loadSameCustomerUsers = async(customerId: string): Promise<void> => {
    const { data } = await CustomerUsersService.listCustomerUsers({
      customerId
    })
    const customerUsers = data.find(c => c.customerId === customerId)
    const sameCustomerUsers = customerUsers?.users?.reduce((acc, user) => {
      acc[user.userId] ??= user
      return acc
    }, {} as {[key: string]: CustomerUser })
    setSameCustomerUsers(sameCustomerUsers || {})
  }


  useEffect(() => {
    const sessionToken = localStorage.getItem("session_token")
    if (sessionToken) {
      // restore session
      void (async(): Promise<void> => {
        try {
          setSession(sessionToken)
          const newSessionToken = await refreshSession()
          await startSession(newSessionToken)
        } catch {
          setUser(null) // stops session restoration loading screen
          await logout()
        }
      })()
    } else {
      setUser(null) // stops session restoration loading screen
    }
  }, [])

  return (
    <AuthContext.Provider value={{ logout, setSessionRefreshInterval, startSession, user, setUser, customer, setCustomer, sameCustomerUsers, principalsCustomer }}>{children}</AuthContext.Provider>
  )
}
