import { FC, useState } from "react"
import Icon from "./Icon"
import Input, { InputProps } from "./Input"

type Omitted = "type" | "suffix"
interface Props extends Omit<InputProps, Omitted> {}

const InputPassword: FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <Input
      type={showPassword ? "text" : "password"}
      suffix={
        <span onClick={(): void => setShowPassword(!showPassword)}>
          <Icon name={showPassword ? "eye-off" : "eye"} />
        </span>
      }
      {...props}
    />
  )
}

export default InputPassword
