import { FC, ReactNode, useEffect, useRef } from "react"
import { toast } from "react-toastify"
import { isToday, isYesterday } from "../helpers/dates"
import { shorten } from "../helpers/text"
import useAuth from "../hooks/useAuth"
import useError from "../hooks/useError"
import useWindowWidth from "../hooks/useWindowWidth"
import { StaffUserDict } from "../pages/TicketDetails"
import { EmailHandler, SupportPlan, Ticket, TicketMessage, TicketsService, UserHandler } from "../ticketsSdk"
import Card from "./Card"
import Icon from "./Icon"
import NewMessageForm from "./NewMessageForm"
import PriorityTag from "./PriorityTag"
import Row from "./Row"
import StatusTag from "./StatusTag"

type TicketCardProps = {
  ticket: Ticket
  ticketStaffUsers: StaffUserDict
  loadTicket(ticketId: string): Promise<Ticket>
  supportPlan?: SupportPlan
}

const STAFF = "Polarity"

const TicketCard: FC<TicketCardProps> = ({ ticket, loadTicket, ticketStaffUsers }) => {
  const { user, customer, sameCustomerUsers } = useAuth()
  const { handleError } = useError()
  const messagesLoadInterval = useRef<number | null>(null)
  const { before } = useWindowWidth()

  const ccNodes = (m: TicketMessage): ReactNode[] =>
    Object.values(m.recipients)
      .filter(r => { // filter message author and ticket author from cc
        const { userId } = (r as UserHandler)
        if (userId) {
          return userId !== ticket.author.userId && userId !== m.author.userId
        }
        return true
      })
      .map((r) => {
        const { email } = r as EmailHandler
        const { userId } = r as UserHandler
        return email
          ? <a href={`mailto:${email}`}>{email}</a>
          : <span>{sameCustomerUsers[userId]?.username}</span>
      })


  const clearMessagesLoadInterval = (): void => {
    if (messagesLoadInterval.current) {
      clearInterval(messagesLoadInterval.current)
    }
  }

  const setMessagesLoadInterval = (): void => {
    clearMessagesLoadInterval()
    const intervalId = setInterval(async() => {
      try {
        const { lastUpdateTimestamp } = await TicketsService.getLastUpdateTimestamp({
          customerId: customer?.customerId,
          ticketHandler: {
            customerId: customer?.customerId || "",
            supportPlanId: customer?.supportPlans[0]?.supportPlanId || "",
            ticketId: ticket.ticketId
          }
        })
        if (lastUpdateTimestamp > ticket.lastUpdateTimestamp) {
          await loadTicket(ticket.ticketId)
        }
      } catch (err) {
        handleError(err, {
          overwrite: {
            TICKET_NOT_FOUND: (): void => {
              void (async(): Promise<void> => {
                try {
                  const { status } = await loadTicket(ticket.ticketId)
                  if (status === "SOLVED") {
                    toast.info("Il ticket è stato chiuso", {
                      autoClose: 7000
                    })
                  } else {
                    handleError(err)
                  }
                } catch (otherError) {
                  handleError(otherError)
                }
              })()
            }
          }
        })
      }
    }, 1000 * 10) // 10 sec

    // eslint-disable-next-line @typescript-eslint/ban-types
    messagesLoadInterval.current = intervalId as unknown as number
  }

  useEffect(() => {
    if (ticket.status !== "SOLVED") {
      setMessagesLoadInterval()
      return clearMessagesLoadInterval
    } else {
      clearMessagesLoadInterval()
    }
  }, [ticket.status])

  const creationDateObject = new Date(ticket.creationTimestamp)
  const creationDate = creationDateObject.toLocaleDateString("it")
  const creationTime = creationDateObject.toLocaleTimeString("it")
  const resolveUserName = (userHandler: UserHandler): string | undefined => {
    if (!userHandler.customerId) {
      const user = ticketStaffUsers ? ticketStaffUsers[userHandler.userId] : undefined
      return user?.username || STAFF
    }
    const user = sameCustomerUsers ? sameCustomerUsers[userHandler.userId] : undefined
    return user?.username
  }

  const messages = ticket.messages?.map((m: TicketMessage) => {
    const res = {
      ...m,
      authorName: resolveUserName(m.author),
      dateTime: new Date(m.timestamp).toLocaleString()
    }
    return res
  })

  const author = resolveUserName(ticket.author)

  const openAttachment = async(fileName: string): Promise<void> => {
    try {
      const file = await toast.promise(
        TicketsService.getAttachmentUrl({
          action: "GET",
          filename: fileName,
          ticketHandler: {
            customerId: customer?.customerId || "",
            supportPlanId: customer?.supportPlans[0]?.supportPlanId || "",
            ticketId: ticket.ticketId
          }
        }),
        {
          pending: fileName
        })
      window?.open?.(file.url, "_blank")?.focus()
    } catch (err) {
      handleError(err)
    }
  }

  return (
    <>
      <div className="mb-2">
        <Row justify="end" className="self-start mb-3 sm:float-right sm:mb-0">
          <Row justify="end" className="min-w-32 mr-2">
            <PriorityTag priority={ticket.priority}/>
          </Row>
          <StatusTag className="" status={ticket?.status}/>
        </Row>
        <span>
          <Icon name="ticket" className="mr-2 mb-1"/>
          <span className="hidden sm:inline">ID:</span> {ticket.ticketId}
        </span>
      </div>
      <Row justify="between" className="mb-2">
        { (author) &&
          <span>
            <Icon name="writing-sign" className="mr-2 mb-1"/>
            <span className="hidden sm:inline">Autore:</span> {author}
          </span>
        }
      </Row>
      <Row justify="between" className="mb-5">
        { (creationDateObject) && <>
          <span className=""><Icon name="clock" className="mr-2 mb-0.5"/>
            {isToday(creationDateObject)
              ? <>Aperto oggi alle {creationTime}</>
              : isYesterday(creationDateObject)
                ? <>Aperto ieri alle {creationTime}</>
                : <>Aperto il {creationDate} alle {creationTime}</>
            }
          </span>
        </>}
      </Row>

      <Card>
        <NewMessageForm
          customer={customer || undefined}
          ticket={ticket}
          user={user || undefined}
          sameCustomerUsers={sameCustomerUsers}
          loadTicket={loadTicket}
          mode="addMessage"
        />
      </Card>

      {messages?.map((m, i) => {
        let messageCcNodes: ReactNode[] = []
        if (ticket.messages && ticket.messages[i]) {
          messageCcNodes = ccNodes(ticket.messages[i])
        }
        return (
          <Card key={i} className="mt-5">
            <Row justify="between">
              <div className="mb-1">
                Da: <h1 className="font-semibold inline">
                  {m.authorName}
                </h1>
              </div>
              <span className="text-gray-400 self-start text-right w-44 ml-5">{m.dateTime}</span>
            </Row>
            <div className="mb-2">
              {messageCcNodes.length > 0 && <>
                <span className="mr-2">CC:</span>
                {messageCcNodes.map((n, i) => {
                  return <div key={i} className="inline-block mr-1">{n}{i < messageCcNodes.length - 1 && ","}</div>
                })}
              </>}
            </div>
            <hr/>
            <div className="whitespace-pre-line mt-5">
              {m.body}
            </div>
            {(m.attachments && m.attachments.length > 0) && <>
              <hr className="mt-5"/>
              <div className="mt-2">Allegati:</div>
              <Row className="flex-wrap">
                {m.attachments.map((a, i) => <>
                  <div
                    key={i}
                    className="border-2 border-gray-200 rounded-full px-2 py-1 hover:border-indigo-400 cursor-pointer mr-3 mt-3 hover:text-indigo-600 hover:underline stroke-black hover:stroke-indigo-600"
                    onClick={async(): Promise<void> => openAttachment(a)}
                  >
                    <Icon name="paperclip" className="mr-2 stroke-inherit" size={23}/>
                    <span className="underline-offset-1 mr-1">{before("sm") ? shorten(a, 20, `..${a.slice(-10)}`) : shorten(a, 100, `..${a.slice(-10)}`) }</span>
                  </div>
                </>)}
              </Row>
            </>}
          </Card>
        )
      }
      )}
    </>
  )
}

export default TicketCard
