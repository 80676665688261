import { useState, useEffect } from "react"

const getCurrentWidth = (): WindowWidth => {
  const { innerWidth: width } = window
  let currentWidth: WindowWidth
  if (width < 640) {
    currentWidth = "xs"
  } else if (width < 768) {
    currentWidth = "sm"
  } else if (width < 1024) {
    currentWidth = "md"
  } else if (width < 1280) {
    currentWidth = "lg"
  } else if (width < 1536) {
    currentWidth = "xl"
  } else {
    currentWidth = "2xl"
  }
  return currentWidth
}

export type WindowWidth = "xs" | "sm" | "md" | "lg" | "xl" | "2xl"

const dimensionsArray: WindowWidth[] = ["xs", "sm", "md", "lg", "xl", "2xl"]

type UseWindowWidth = {
  windowWidth: WindowWidth
  from(from: WindowWidth): boolean
  before(before: WindowWidth): boolean
}

const useWindowWidth = (): UseWindowWidth => {
  const [windowWidth, setWindowWidth] = useState<WindowWidth>(getCurrentWidth())

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(getCurrentWidth())
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const from = (from: WindowWidth): boolean => {
    const fromIndex = dimensionsArray.indexOf(from)
    const currentIndex = dimensionsArray.indexOf(windowWidth, fromIndex)
    return currentIndex !== -1
  }

  const before = (before: WindowWidth): boolean => {
    const beforeIndex = dimensionsArray.indexOf(before)
    const currentIndex = dimensionsArray.indexOf(windowWidth)
    return currentIndex < beforeIndex
  }

  return {
    windowWidth,
    from,
    before
  }
}

export default useWindowWidth