import classNames from "classnames"
import { FC, HTMLProps, ReactNode } from "react"

export interface CardProps extends HTMLProps<HTMLDivElement> {
  heading?: ReactNode
  headingProps?: HTMLProps<HTMLDivElement>
  spacing?: boolean
  border?: boolean
}

const Card: FC<CardProps> = ({
  children,
  className,
  heading,
  headingProps,
  spacing = true,
  border = true,
  ...props
}) => {
  return (
    <div
      className={classNames("card", className, border || "no-border")}
      {...props}
    >
      {heading
        ?
        <div
          {...headingProps}
          className={classNames(
            "card-heading",
            headingProps?.className
          )}
        >
          {heading}
        </div>
        : null
      }

      <div className={classNames("card-content", spacing || "no-spacing")}>
        {children}
      </div>
    </div>
  )
}

export default Card
