import { Customer as PrincipalsCustomer, CustomersService as PrincipalsCustomersService, CustomerUser, CustomerUsersService, OpenAPI as principalsConfig } from "../principalsSdk"
import { Customer, CustomersService, OpenAPI as ticketsConfig, UserHandler } from "../ticketsSdk"
import { SessionData } from "../types/auth"
import { decodeBase64 } from "./base64"

export const setSession = (session: string): void => {
  // set openapi session
  principalsConfig.TOKEN = session || undefined
  ticketsConfig.TOKEN = session || undefined

  // store token
  localStorage.setItem("session_token", session)
}

export const getSession = (): string | undefined => {
  return  principalsConfig.TOKEN?.toString() || ticketsConfig.TOKEN?.toString() || localStorage.getItem("session_token") || undefined
}

export const refreshSession = async(): Promise<string> => {
  const { session: newSession } = await CustomerUsersService.refreshCustomerUserSession()
  return newSession
}

export const getSessionData = (session: string): SessionData => {
  const sessionData: SessionData = JSON.parse(decodeBase64(session))
  return sessionData
}

export const getUserFromSessionData = async(sessionData: SessionData): Promise<CustomerUser> => {
  const data = {
    handler: {
      customerId: sessionData.customerId,
      userId: sessionData.userId
    }
  }
  const { data: customerUser } = await CustomerUsersService.getCustomerUser(data)

  return customerUser
}

export const getTicketsCustomer = async(customerId: string): Promise<Customer> => {
  const { data: customer } = await CustomersService.getCustomer({
    handler: {
      customerId
    }
  })
  return customer
}

export const getPrincipalsCustomer = async(customerId: string): Promise<PrincipalsCustomer> => {
  const { data: customer } = await PrincipalsCustomersService.getCustomer({
    handler: {
      customerId
    }
  })
  return customer
}

export const STAFF = "Polarity"

export const resolveUserName = (userHandler: UserHandler, sameCustomerUsers: {[key: string]: CustomerUser}): string | undefined => {
  if (!userHandler.customerId) {
    return STAFF
  }
  const user = sameCustomerUsers ? sameCustomerUsers[userHandler.userId] : undefined
  return user?.username
}