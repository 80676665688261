import { ReactElement, ReactNode } from "react"
import { Navigate } from "react-router-dom"
import Row from "../../components/Row"
import Spinner from "../../components/Spinner"
import { privateRedirectPath } from "../../constants/routes"
import useAuth from "../../hooks/useAuth"

type Props = {
  children: ReactNode
}

export default function AuthWrapper({ children }: Props): ReactElement {
  const { user } = useAuth()

  return (
    <>
      {user === undefined
        ?
        <Row justify="center" className="w-full h-full">
          <Spinner/>
        </Row>
        : user
          ? <Navigate to={privateRedirectPath} replace/>
          : children
      }
    </>
  )
}
