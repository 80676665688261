import { FC } from "react"
import { Link } from "react-router-dom"
import Card from "../components/Card"
import Icon from "../components/Icon"
import NewMessageForm from "../components/NewMessageForm"
import Row from "../components/Row"
import { privateRedirectPath } from "../constants/routes"
import useAuth from "../hooks/useAuth"

const NewTicket: FC = () => {
  const { user, customer } = useAuth()
  return (
    <>
      <Row justify="center">
        <div className="w-full max-w-7xl">
          <Row className="mb-3">
            <Link to={privateRedirectPath} className="mr-3">
              <Icon name="arrow-left"/>
            </Link>
            <h1 className="text-3xl font-semibold">Apri un ticket</h1>
          </Row>
          <Card className="w-full">
            <NewMessageForm
              mode="newTicket"
              customer={customer || undefined}
              user={user || undefined}
            />
          </Card>
        </div>
      </Row>
    </>
  )
}

export default NewTicket
