import classNames from "classnames"
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { privateRedirectPath } from "../constants/routes"
import useError from "../hooks/useError"
import { CustomerUser } from "../principalsSdk"
import { Customer, EmailHandler, Recipient, Ticket, TicketHandler, TicketPriority, TicketsService, UserHandler } from "../ticketsSdk"
import AttachmentsInput from "./AttachmentsInput"
import Button from "./Button"
import CcForm from "./CcForm"
import FileCard from "./FileCard"
import Input from "./Input"
import Row from "./Row"
import Spinner from "./Spinner"
import Textarea from "./Textarea"


type AddMessageForm = {
  title?: string
  priority?: TicketPriority
  newStatus: "open" | "closed" | "same"
  body: string
}

type UserDict = {[key: string]: CustomerUser}

type UserOrEmailDict = {[key: string]: CustomerUser | string}

type Props = {
  user?: CustomerUser
  customer?: Customer
  ticket?: Ticket
  sameCustomerUsers?: UserDict
  loadTicket?(ticketId: string): Promise<Ticket>
  mode: "addMessage" | "newTicket"
}

const getDefaultCc = (ticket: Ticket, user: CustomerUser, sameCustomerUsers: UserDict): UserOrEmailDict => {
  const defaultCc: UserOrEmailDict = {}

  if (ticket.messages && ticket.messages[0]) {
    ticket.messages[0].recipients.forEach(r => {
      const { email } = r as EmailHandler
      if (email) {
        defaultCc[email] ??= email
      } else {
        const { userId } = r as UserHandler
        if (userId !== user.userId && userId !== ticket.author.userId) {
          defaultCc[userId] ??= sameCustomerUsers[userId]
        }
      }
    })
    // no need to add author, it's already inside message[0]'s recipients
    // if (ticket.messages[0].author.customerId && ticket.messages[0].author.userId !== user.userId) {
    //   defaultCc[ticket.messages[0].author.userId] ??= sameCustomerUsers[ticket.messages[0].author.userId]
    // }
  }
  return defaultCc
}

const getCcHandlers = (cc: UserOrEmailDict): Recipient[] =>
  Object.values(cc).map(u => {
    return typeof u === "string"
      ? {
        email: u
      }
      : {
        userId: u.userId,
        customerId: u.customerId
      }
  })


const NewMessageForm: FC<Props> = ({
  user,
  customer,
  ticket,
  sameCustomerUsers = {},
  loadTicket,
  mode
}) => {

  const { control, formState, reset, handleSubmit, setValue } = useForm<AddMessageForm>()
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false)
  const [changeStatusAndSendLoading, setChangeStatusAndSendLoading] = useState<boolean>(false)
  const [cc, setCc] = useState<UserOrEmailDict>(user && ticket ? getDefaultCc(ticket, user, sameCustomerUsers) : {})
  const [filesToUpload, setFilesToUpload] = useState<{[fileName: string]: File}>({})
  const { handleError } = useError()
  const navigate = useNavigate()

  const removeFile = (file: File): void => {
    if (filesToUpload[file.name]) {
      const newFilesDict = { ...filesToUpload }
      delete newFilesDict[file.name]
      setFilesToUpload(newFilesDict)
    }
  }

  const handleTicketClose = async(): Promise<void> => {
    if (loadTicket) {
      await TicketsService.closeTicket({
        handler: {
          userId: user?.userId || "",
          customerId: user?.customerId || ""
        },
        ticketHandler: {
          customerId: user?.customerId || "",
          supportPlanId: customer?.supportPlans[0]?.supportPlanId || "",
          ticketId: ticket?.ticketId || ""
        }
      })
    }
  }

  const handleTicketReopen = async(): Promise<void> => {
    if (loadTicket) {
      await TicketsService.reopenTicket({
        ticketHandler: {
          customerId: user?.customerId || "",
          supportPlanId: customer?.supportPlans[0]?.supportPlanId || "",
          ticketId: ticket?.ticketId || ""
        },
        handler: {
          userId: user?.userId || "",
          customerId: customer?.customerId || ""
        }
      })
    }
  }

  const loadAttachments = async(files: File[], ticketHandler: TicketHandler): Promise<void> => {
    const attachmentsPromises = files.map(f => {
      return TicketsService.getAttachmentUrl({
        action: "PUT",
        filename: f.name,
        ticketHandler
      })
    })
    const attachmentsUrls = (await Promise.all(attachmentsPromises)).map(res => res.url)

    const totalSize = files.reduce((sum, f) => sum + f.size, 0)
    const thereshold = 1 * 1000 * 1000 // 1 MB
    const toasts =  {
      pending: "Caricamento allegati in corso, attendere..."
    }

    await toast.promise(
      Promise.all(attachmentsUrls.map((url, i) => {
        return fetch(
          url,
          {
            method: "PUT",
            body: files[i]
          })
      })),
      totalSize > thereshold
        ? toasts
        : {}
    )
  }


  const onSubmitAddMessage = async({ body, newStatus }: AddMessageForm): Promise<void> => {
    if (loadTicket) {
      const setLoading = newStatus === "same"
        ? setSendMessageLoading
        : setChangeStatusAndSendLoading
      setLoading(true)


      try {
        const currentTicket = await loadTicket(ticket?.ticketId || "")
        const currentStatus = currentTicket.status === "SOLVED" ? "closed" : "open"
        if (currentStatus === newStatus) {
          const message = currentStatus === "open"
            ? "Il ticket è già aperto"
            : "Il ticket è già risolto"
          toast.info(message, {
            autoClose: 7000
          })
          setLoading(false)
          return
        }

        // if user wants to reopen the ticket
        if (newStatus === "open") {
          // reopen ticket before sending the message
          await handleTicketReopen()
        }

        // send message
        const ticketHandler = {
          customerId: currentTicket.customerId,
          supportPlanId: currentTicket.supportPlanId,
          ticketId: currentTicket.ticketId
        }
        const files = Object.values(filesToUpload)
        const fileNames = Object.keys(filesToUpload)
        if (files.length > 0) {
          await loadAttachments(files, ticketHandler)
        }
        await TicketsService.addMessageToTicket({
          body,
          handler: {
            userId: user?.userId || "",
            customerId: customer?.customerId
          },
          ticketHandler,
          cc: getCcHandlers(cc),
          nextStatus: "IDLE",
          attachments: fileNames
        })


        // if user wants to close the ticket
        if (newStatus === "closed") {
          // close ticket after sending the message
          await handleTicketClose()
        }

        // refresh ticket and clean form
        await loadTicket(ticket?.ticketId || "")
        reset()
        setFilesToUpload({})

        toast.success("Messaggio inviato correttamente")
      } catch (err) {
        handleError(err, {
          overwrite: {
            TICKET_NOT_FOUND: async(): Promise<void> => {
              try {
                const { status } = await loadTicket(ticket?.ticketId || "")
                if (status === "SOLVED" && ticket?.status !== "SOLVED") {
                  toast.info("Il ticket è stato chiuso", {
                    autoClose: 7000
                  })
                } else if (status !== "SOLVED" && ticket?.status === "SOLVED") {
                  toast.info("Il ticket è stato riaperto", {
                    autoClose: 7000
                  })
                } else {
                  handleError(err)
                }
              } catch (otherError) {
                handleError(otherError)
              }
            }
          }
        })
      }
      setLoading(false)
    }
  }

  const onSubmitNewTicket = async(data: AddMessageForm): Promise<void> => {
    setSendMessageLoading(true)
    try {
      const files = Object.values(filesToUpload)
      const fileNames = Object.keys(filesToUpload)

      const { data: ticketHandler } = await TicketsService.createTicket({
        handler: {
          userId: user?.userId || "",
          customerId: customer?.customerId || ""
        },
        priority: data.priority || "LOW",
        customerId: user?.customerId || "",
        supportPlanId: customer?.supportPlans[0]?.supportPlanId || "",
        title: data.title || "",
        body: data.body,
        cc: getCcHandlers(cc),
        attachments: fileNames
      })
      if (files.length > 0) {
        await loadAttachments(files, ticketHandler)
      }
      toast.success("Ticket creato correttamente")
      navigate(privateRedirectPath)
    } catch (err) {
      handleError(err)
    }
    setSendMessageLoading(false)
  }

  const submit = handleSubmit(mode === "addMessage" ? onSubmitAddMessage : onSubmitNewTicket)

  return (<>
    <form onSubmit={submit} className="mb-0">
      {mode === "newTicket" && <>
        <Controller
          name="title"
          control={control}
          rules={{
            required: "Campo obbligatorio"
          }}
          defaultValue=""
          render={({ field: { onChange, value } }): ReactElement => (
            <Input
              className="mb-4"
              label="Titolo"
              type="text"
              value={value}
              onChange={onChange}
              error={formState.errors.title?.message}
            />
          )}
        />
        <Controller
          name="priority"
          control={control}
          rules={{
            required: "Campo obbligatorio"
          }}
          defaultValue="LOW"
          render={({ field: { onChange, value } }): ReactElement => (
            <div className="mb-5">
              <label className="input__label">Priorità</label>
              <select
                className="border outline-none border-gray-200 hover:border-gray-300 rounded focus-visible:ring-indigo-300 focus-visible:border-indigo-300 block w-full p-2"
                value={value}
                onChange={onChange}
              >
                <option value="LOW">Bassa</option>
                <option value="MEDIUM">Media</option>
                <option value="HIGH">Alta</option>
                <option value="CRITICAL">Critica</option>
              </select>
            </div>
          )}
        />
      </>}
      <Row className="mb-3">
      Da: <h1 className="text-base font-semibold ml-2">
          {user?.username}
        </h1>
      </Row>
      <Row className="mb-3">
        <span className="mr-2 self-start">CC:</span>
        <CcForm cc={cc} setCc={setCc} ticketAuthorId={ticket?.author.userId || ""}/>
      </Row>
      <Controller
        name="body"
        control={control}
        rules={{
          required: "Campo obbligatorio"
        }}
        defaultValue=""
        render={({ field: { onChange, value } }): ReactElement => (
          <Textarea
            aria-multiline={true}
            placeholder="Scrivi un commento..."
            value={value}
            onChange={onChange}
            className="h-40"
            error={formState.errors.body?.message}
            errorProps={{
              className: "hidden"
            }}
            textareaProps={{
              className: "pb-14"
            }}
          />
        )}
      />
      <div className="relative">
        <Row justify="end" className={classNames("absolute right-5 left-2 pb-2 pt-1 bg-white")} style={{ bottom: "1px" }}>
          <AttachmentsInput
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
          />
          {mode === "addMessage" &&
            <Button size="xs" theme="secondary" type="button" className="relative min-w-32 h-10 mr-4 last:mr-0"
              onClick={async(): Promise<void> => {
                if (changeStatusAndSendLoading) {
                  return
                }
                setValue("newStatus", ticket?.status === "SOLVED" ? "open" : "closed")
                await submit()
                setValue("newStatus", "same")
              }}
            >
              {changeStatusAndSendLoading
                ? <Spinner color="main" size={18.75}/>
                : ticket?.status === "SOLVED"
                  ? "Riapri e invia"
                  : "Invia e risolvi"
              }
            </Button>
          }
          {ticket?.status !== "SOLVED" &&
            <Button size="xs" type={sendMessageLoading ? "button" : "submit"} className="relative min-w-16 h-10">
              {sendMessageLoading ? <Spinner color="white" size={18.75}/> : "Invia"}
            </Button>
          }
        </Row>
      </div>

      {formState.errors.body?.message
        ?
        <div
          className={classNames("input__error")}
        >
          {formState.errors.body?.message}
        </div>
        : null
      }

      <Controller
        name="newStatus"
        control={control}
        rules={{
          required: "Campo obbligatorio"
        }}
        defaultValue={"same"}
        render={({ field: { onChange, value } }): ReactElement => (
          <input
            type={"hidden"}
            aria-multiline={true}
            placeholder="Scrivi un commento..."
            value={value}
            onChange={onChange}
            name="changeStatus"
          />
        )}
      />
    </form>
    {filesToUpload && Object.values(filesToUpload).length > 0 && <>
      <div className="mt-5 mr-3">Allegati:</div>
      <Row className="flex-wrap">
        {Object.values(filesToUpload).map(f => <>
          <FileCard file={f} removeFile={removeFile} />
        </>)}
      </Row>
    </>}
  </>)
}

export default NewMessageForm