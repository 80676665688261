import { FC, ReactElement, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Button from "../components/Button"
import Card from "../components/Card"
import Col from "../components/Col"
import Input from "../components/Input"
import Row from "../components/Row"
import useError from "../hooks/useError"
import { CustomerUsersService, OpenAPI, RequestResetCustomerUserPasswordRequestSchema, Timestamp } from "../principalsSdk"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { decodeBase64 } from "../helpers/base64"
import InputPassword from "../components/InputPassword"
import { privateRedirectPath } from "../constants/routes"

type ResetForm = {
  newPassword: string
  newPasswordConfirm: string
}

type ResetPasswordTokenObjectScheme = {
  sessionId: string
  userId: string
  customerId: string
  email: string
  roles: string[]
  creationTimestamp: Timestamp
  expirationTimestamp: Timestamp
}

const ResetPassword: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { handleError } = useError()
  const { control: requestControl, formState: requestFormState, handleSubmit: requestHandleSubmit } = useForm<RequestResetCustomerUserPasswordRequestSchema>()
  const { control: resetControl, formState: resetFormState, handleSubmit: resetHandleSubmit, getValues: resetGetValues } = useForm<ResetForm>()
  const [searchParams] = useSearchParams()
  const [resetPasswordTokenObject, setResetPasswordTokenObject] = useState<ResetPasswordTokenObjectScheme | undefined>(undefined)
  const navigate = useNavigate()

  const onSubmitRequest = async(formData: RequestResetCustomerUserPasswordRequestSchema): Promise<void> => {
    setLoading(true)
    try {
      await CustomerUsersService.requestResetCustomerUserPassword(formData)
      toast.success(`Controlla la casella di posta di ${formData.email}, se esiste un account associato ti manderemo un link per il reset della password`, {
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        autoClose: 20000
      })
    } catch (err) {
      handleError(err)
    }
    setLoading(false)
  }

  const onSubmitReset = async(formData: ResetForm): Promise<void> => {
    setLoading(true)
    try {
      await CustomerUsersService.resetCustomerUserPassword({
        handler: {
          customerId: resetPasswordTokenObject?.customerId || "",
          userId: resetPasswordTokenObject?.userId || ""
        },
        newPassword: formData.newPassword
      })
      OpenAPI.TOKEN = undefined
      toast.success("Password salvata correttamente")
      navigate(privateRedirectPath)
    } catch (err) {
      handleError(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    const resetPasswordToken = searchParams.get("token")
    if (resetPasswordToken) {
      try {
        const tokenObject = JSON.parse(decodeBase64(resetPasswordToken))
        setResetPasswordTokenObject(tokenObject)
        OpenAPI.TOKEN = resetPasswordToken
      } catch (err) {
        toast.error("Token non valido")
      }
    }
  }, [])

  return (
    <div className={"container h-full items-center grid grid-cols-12 px-4 sm:px-6 lg:px-8"}>
      <Col lg={4} className="lg:col-start-5">
        {resetPasswordTokenObject
          ?
          <Card>
            <h1 className="text-xl font-bold text-center mb-5">Imposta una nuova Password</h1>

            <form onSubmit={resetHandleSubmit(onSubmitReset)}>
              <Controller
                name="newPassword"
                control={resetControl}
                rules={{
                  required: "Campo obbligatorio"
                }}
                defaultValue=""
                render={({ field: { onChange, value } }): ReactElement => (
                  <InputPassword
                    className="mb-2"
                    label="Nuova password"
                    value={value}
                    onChange={onChange}
                    error={resetFormState.errors.newPassword?.message}
                  />
                )}
              />

              <Controller
                name="newPasswordConfirm"
                control={resetControl}
                rules={{
                  required: "Campo obbligatorio",
                  validate: (val): string | boolean => val === resetGetValues("newPassword") || "Le password non combaciano"
                }}
                defaultValue=""
                render={({ field: { onChange, value } }): ReactElement => (
                  <InputPassword
                    className="mb-2"
                    label="Conferma password"
                    value={value}
                    onChange={onChange}
                    error={resetFormState.errors.newPasswordConfirm?.message}
                  />
                )}
              />

              <Row className="mt-5" justify="center">
                <Button type="submit" disabled={loading}>Salva password</Button>
              </Row>
            </form>
          </Card>
          :
          <Card>
            <h1 className="text-xl font-bold text-center">Reset della Password</h1>

            <p className="mt-1 mb-4 text-sm text-gray-400 text-center">Riceverai una email con il link per effettuare il reset della password</p>

            <form onSubmit={requestHandleSubmit(onSubmitRequest)}>
              <Controller
                name="email"
                control={requestControl}
                rules={{
                  required: "Campo obbligatorio"
                }}
                defaultValue=""
                render={({ field: { onChange, value } }): ReactElement => (
                  <Input
                    className="mb-2"
                    label="Email"
                    type="email"
                    value={value}
                    onChange={onChange}
                    error={requestFormState.errors.email?.message}
                  />
                )}
              />
              <Row className="mt-2">
                <Link to={"/login"}>
                  <span className="underline text-indigo-600 text-sm">Effettua login</span>
                </Link>
              </Row>

              <Row className="mt-4" justify="center">
                <Button type="submit" disabled={loading}>Invia email</Button>
              </Row>
            </form>
          </Card>
        }
      </Col>
    </div>
  )
}

export default ResetPassword
