import classNames from "classnames"
import { FC, HTMLProps, ReactNode, useState } from "react"
import Icon from "./Icon"
import Row from "./Row"

export interface InputProps extends Omit<HTMLProps<HTMLInputElement>, "label" | "onChange" | "prefix"> {
  type?: "text" | "email" | "password" | "tel"
  onChange?(value: string): void
  label?: ReactNode
  labelProps?: HTMLProps<HTMLLabelElement>
  error?: ReactNode
  errorProps?: HTMLProps<HTMLDivElement>
  showNoResultsMessage?: boolean
  hints: Hint[]
  selectHint(id: string, isEmail?: boolean): void
}

export type Hint = {
  id: string
  value: string
}

const emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const emailReFirsStep = /^[^\s@]+@?$/
const emailReSecondStep = /^[^\s@]+@[^\s@.]+\.?$/

type emailCompletion = {
  userWritten: string     // part of email written by the user
  hint: string            // part of email suggested by autocompletion
  isComplete: boolean     // userWritten is a valid email
  isCompletable: boolean  // can be completed merging userWritten and hint
} | undefined

const getEmailCompletion = (email: string): emailCompletion => {
  if (emailReFirsStep.test(email)) {
    return {
      userWritten: email,
      hint: `${email.endsWith("@") ? "" : "@"}mail.com`,
      isComplete: false,
      isCompletable: false
    }
  } else if (emailReSecondStep.test(email)) {
    return {
      userWritten: email,
      hint: `${email.endsWith(".") ? "" : "."}com`,
      isComplete: false,
      isCompletable: true
    }
  } else if (emailRe.test(email)) {
    return {
      userWritten: email,
      hint: "",
      isComplete: true,
      isCompletable: true
    }
  }
}

const InputBubble: FC<InputProps> = ({
  className,
  type = "text",
  label,
  labelProps,
  error,
  errorProps,
  onChange,
  placeholder,
  hints,
  showNoResultsMessage = true,
  selectHint,
  value: textInputValue,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const plusAction = (): void => {
    setOpen(true)
  }

  let displayedHints: Hint[] = []
  if (hints) {
    displayedHints = hints.slice(0, 5)
  }

  let emailCompletion: emailCompletion
  if (displayedHints.length === 0) {
    emailCompletion = getEmailCompletion(textInputValue as string)
  }

  const displayHints = displayedHints && displayedHints.length > 0
  return (<>
    <div
      className={classNames(className, "h-8")}
      onBlur={(event): void => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setOpen(false)
        }
      }}
    >
      {label
        ?
        <label
          {...labelProps}
          className={classNames("input-bubble__label", labelProps?.className)}
        >{label}</label>
        : null
      }

      <Row className="input-bubble__wrapper">
        {open
          ? <input

            onKeyDown={(e): void => {
              if (e.code === "Enter") {
                e.preventDefault()
                if (displayedHints.length > 0) {
                  selectHint(displayedHints[0].id)
                } else if (emailCompletion?.isCompletable) {
                  if (emailCompletion?.isCompletable) {
                    const completion = emailCompletion.userWritten + emailCompletion.hint
                    selectHint(completion, true)
                  }
                }
              }
            }}
            autoFocus={true}
            className={classNames("input-bubble", {
              ["input-bubble--error"]: !!error,
              ["input-bubble--closed"]: !open
            })}
            value={textInputValue}
            type={type}
            onChange={(e): void => onChange?.(e.target.value)}
            placeholder={open ? placeholder : ""}
            {...props}
          />
          : <Row className="input-bubble__plus-button" onClick={plusAction}>
            <Icon name="plus" size={21} className="input-bubble__plus-icon" />
          </Row>
        }
      </Row>

      {error
        ?
        <div
          {...errorProps}
          className={classNames("input-bubble__error", errorProps?.className)}
        >
          {error}
        </div>
        : null
      }
      {open &&
        <div tabIndex={0} className={classNames("input-bubble__popup", !displayHints && !emailCompletion && !showNoResultsMessage && "hidden")}>
          {displayHints
            ? displayedHints.map(({ id, value }, i) => <div key={i} className="first:rounded-t-md last:rounded-b-md  hover:bg-indigo-50">
              {i > 0 &&
              <hr className="border-gray-300"/>
              }
              <div onClick={(): void => {
                selectHint(id)
                setOpen(false)
              }} className="p-2">
                {value}
              </div>
            </div>)
            : emailCompletion
              ? <>
                <div className="first:rounded-t-md last:rounded-b-md  hover:bg-indigo-50">
                  <div onClick={(): void => {
                    if (emailCompletion?.isCompletable) {
                      const completion = emailCompletion.userWritten + emailCompletion.hint
                      selectHint(completion, true)
                      setOpen(false)
                    }
                  }} className="p-2">
                    <span>{emailCompletion.userWritten}</span><span className="italic text-gray-400">{emailCompletion.hint}</span>
                  </div>
                </div>
              </>
              : showNoResultsMessage
                ? <div className="p-2 text-gray-400">Nessun risultato...</div>
                : ""
          }
        </div>
      }
    </div>
  </>
  )
}

export default InputBubble