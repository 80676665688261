import classNames from "classnames"
import { FC, HTMLProps } from "react"
import { ButtonSize, ButtonTheme, ButtonType } from "../types/ui"

interface Props extends Omit<HTMLProps<HTMLButtonElement>, "size"> {
  size?: ButtonSize
  theme?: ButtonTheme
  type?: ButtonType
}

const Button: FC<Props> = ({
  children,
  className,
  size = "md",
  theme = "primary",
  ...props
}: Props) => {
  return (
    <button
      className={classNames("btn", `btn--${theme}`, `btn--${size}`, className)}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
