import { toast } from "react-toastify"
import { errorMessages } from "../constants/errors"
import useAuth from "./useAuth"

type ApiError = {
  error: {
    code?: string
    message: string
  }
  status: number
  statusCode: string
}

// key: error.code
type HandleErrorOverwrite = {
  [key: string]: ((err: ApiError) => void) | null
}
type HandleErrorOptions = {
  overwrite?: HandleErrorOverwrite
}

type UseError = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(err: any, options?: HandleErrorOptions): void
}

const useError = (): UseError => {
  const { logout } = useAuth()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (err: any, options?: HandleErrorOptions): void => {
    if (err.body) {
      const apiError = err.body as ApiError

      let message: string
      if (apiError) {
        if (options?.overwrite && apiError.error.code && apiError.error.code in options.overwrite) {
          if (options.overwrite[apiError.error.code] !== null) {
            options.overwrite[apiError.error.code]!(apiError)
          }
          return
        }

        message = apiError.error.code && errorMessages[apiError.error.code] ? errorMessages[apiError.error.code] : apiError.error.message || apiError.statusCode
        toast.error(message)

        if (apiError.error.code === "EXPIRED_JWT") {
          void logout()
        }
      }

    } else {
      // handle generic error
    }
  }

  return {
    handleError
  }
}

export default useError
