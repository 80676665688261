import { FC } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../components/Button"
import Icon from "../components/Icon"
import Row from "../components/Row"
import Tabs from "../components/Tabs"
import TicketsTable from "../components/TicketsTable"
import useWindowWidth from "../hooks/useWindowWidth"

const ListTickets: FC = () => {
  const navigate = useNavigate()
  const { before } = useWindowWidth()
  const mobileWiew: boolean = before("sm")
  return <>
    {mobileWiew &&
      <Row justify="center">
        <Button
          onClick={(): void => navigate("/tickets/new")}
          className="w-full mb-3"
        >
          <Icon name="plus" className="stroke-white mr-2 -my-1"/>Nuovo Ticket
        </Button>
      </Row>
    }
    <Row justify="center">
      <Tabs
        className="max-w-7xl"
        tabs={[
          {
            id: "unresolved",
            name: "Ticket Aperti",
            content: <TicketsTable unresolved={true}/>
          },
          {
            id: "resolved",
            name: "Ticket Chiusi",
            content: <TicketsTable unresolved={false}/>
          }
        ]}
        justify={mobileWiew ? "around" : "start"}
        topRightElement={
          mobileWiew
            ? undefined
            : <Button
              onClick={(): void => navigate("/tickets/new")}
            >
              <Icon name="plus" className="stroke-white mr-2 -my-1"/>Nuovo Ticket
            </Button>
        }
      />
    </Row>
  </>
}

export default ListTickets
