import classNames from "classnames"
import { FC } from "react"
import { TicketStatus } from "../ticketsSdk"
import Tag from "./Tag"

type Props = {
  status: TicketStatus | undefined
  className?: string
}

const statusTranslation = {
  UNASSIGNED: "Non assegnato",
  PENDING_FIRST_INTERACTION: "Non assegnato",
  PENDING_CUSTOMER: "Azione richiesta",
  WORK_IN_PROGRESS: "In lavorazione",
  IDLE: "In lavorazione",
  PENDING_STAFF: "In lavorazione",
  SOLVED: "Risolto"
}

const StatusTag: FC<Props> = ({ status, className }) => {

  return (<>
    { status &&
      <div className={classNames(className)}>
        <Tag
          className={classNames(status !== "SOLVED" && "min-w-36")}
          outline={true}
          theme={
            status === "SOLVED"
              ? "green"
              : status === "PENDING_CUSTOMER"
                ? "yellow"
                : "blue"
          }>
          {statusTranslation[status] || status}
        </Tag>
      </div>
    }
  </>)
}

export default StatusTag