import classNames from "classnames"
import { FC } from "react"
import { TicketPriority } from "../ticketsSdk"
import Tag from "./Tag"

type Props = {
  priority: TicketPriority
  className?: string
  hasPrefix?: boolean
}

const priorityTranslation = {
  LOW: "Bassa",
  MEDIUM: "Media",
  HIGH: "Alta",
  CRITICAL: "Critica"
}

const PREFIX = "Priorità"

const PriorityTag: FC<Props> = ({
  priority,
  className,
  hasPrefix = true
}) => {

  return (<>
    { priority &&
      <div className={classNames(className)}>
        <Tag
          outline={true}
          className={classNames(!hasPrefix && "min-w-20")}
          theme={
            priority === "LOW"
              ? "blue"
              : priority === "MEDIUM"
                ? "yellow"
                : "red"
          }>
          {priorityTranslation[priority]
            ? hasPrefix
              ? [PREFIX, priorityTranslation[priority].toLocaleLowerCase()].join(" ")
              : priorityTranslation[priority]
            : priority
          }
        </Tag>
      </div>
    }
  </>)
}

export default PriorityTag