/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteCustomerRequestSchema } from '../models/DeleteCustomerRequestSchema';
import type { GetCustomer200ResponseSchema } from '../models/GetCustomer200ResponseSchema';
import type { GetCustomerRequestSchema } from '../models/GetCustomerRequestSchema';
import type { OkSchema } from '../models/OkSchema';
import type { UpsertCustomerRequestSchema } from '../models/UpsertCustomerRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomersService {

    /**
     * upsertCustomer
     * Upsert customer
     * @param requestBody
     * @returns OkSchema UpsertCustomer200Response
     * @throws ApiError
     */
    public static upsertCustomer(
        requestBody?: UpsertCustomerRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/upsertCustomer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * getCustomer
     * Get customer
     * @param requestBody
     * @returns GetCustomer200ResponseSchema GetCustomer200Response
     * @throws ApiError
     */
    public static getCustomer(
        requestBody?: GetCustomerRequestSchema,
    ): CancelablePromise<GetCustomer200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/getCustomer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * deleteCustomer
     * Delete customer
     * @param requestBody
     * @returns OkSchema DeleteCustomer200Response
     * @throws ApiError
     */
    public static deleteCustomer(
        requestBody?: DeleteCustomerRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/deleteCustomer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
