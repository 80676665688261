export const isSameDate = (date1: Date, date2: Date): boolean =>
  date1.getDate() === date2.getDate()
  && date1.getMonth() === date2.getMonth()
  && date1.getFullYear() === date2.getFullYear()

export const isToday = (date: Date): boolean => isSameDate(date, new Date())

export const isYesterday = (date: Date): boolean => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  return isSameDate(date, yesterday)
}