import { FC } from "react"
import { shorten } from "../helpers/text"
import useWindowWidth from "../hooks/useWindowWidth"
import Bubble from "./Bubble"
import Icon from "./Icon"
import Row from "./Row"


const getByteSize = (bytes: number): string => {
  const labels = [" B", " KB", " MB", " GB"]
  for (let i = 0; i < labels.length; i++) {
    if (bytes < Math.pow(1000, i + 1)) {
      return Math.round(bytes / Math.pow(1000, i) * 10) / 10 + labels[i]
    }
  }
  return ""
}

type Props = {
  file: File
  removeFile(f: File): void
}

const FileCard: FC<Props> = ({
  file,
  removeFile
}) => {
  const { before } = useWindowWidth()

  return (
    <Bubble
      className="file-card"
      onClose={(): void => removeFile(file)}
    >
      <Row
        className="file-card--inner"
        onClick={(): void => {
          window?.open?.(URL.createObjectURL(file))?.focus()
        }}
      >
        <Icon
          name="file-invoice"
          className="file-card--icon"
        />
        <span className="file-card--text">{before("sm") ? shorten(file.name, 10, `..${file.name.slice(-5)}`) : shorten(file.name, 100, `..${file.name.slice(-10)}`) } ({getByteSize(file.size)})</span>
      </Row>
    </Bubble>
  )
}

export default FileCard