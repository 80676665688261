import { FC, ReactElement, useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Card from "../components/Card"
import Row from "../components/Row"
import useError from "../hooks/useError"
import { CustomerUsersService } from "../principalsSdk"
import useAuth from "../hooks/useAuth"
import { Link, useNavigate } from "react-router-dom"
import { getSession, getSessionData } from "../helpers/session"
import { SessionData } from "../types/auth"
import InputMfaCode from "../components/InputMfaCode"


const Mfa: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { startSession } = useAuth()
  const { handleError } = useError()
  const { control, formState, handleSubmit } = useForm<{ token: string }>()
  const sessionData = useRef<SessionData>()


  useEffect(() => {
    const session = getSession()
    if (session) {
      sessionData.current = getSessionData(session)
    } else {
      navigate("/login")
    }
  }, [])

  const onSubmit = async({ token }: { token: string }): Promise<void> => {
    setLoading(true)

    try {
      const { session } = await CustomerUsersService.verifyCustomerUserMfaToken({
        handler: {
          customerId: sessionData.current?.customerId || "",
          userId: sessionData.current?.userId || ""
        },
        token
      })

      sessionData.current = getSessionData(session)
      await startSession(session)
    } catch (err) {
      handleError(err)
    }
    setLoading(false)
  }

  return (
    <Row className="h-full w-full" justify="center">
      <div className={"self-center w-full max-w-sm"}>
        <Card>
          <h1 className="text-xl font-bold text-center">Multi Factor Authentication</h1>

          <p className="mt-1 mb-4 text-sm text-gray-400 text-center">Inserisci il codice temporaneo per accedere</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Row justify="center">
              <Controller
                name="token"
                control={control}
                rules={{
                  required: "Campo obbligatorio"
                }}
                defaultValue=""
                render={({ field: { onChange, value } }): ReactElement => (
                  <InputMfaCode
                    onChange={onChange}
                    value={value}
                    error={formState.errors.token?.message}
                    disabled={loading}
                    loading={loading}
                    className="w-full"
                  />
                )}
              />
            </Row>
            <Row className="mt-2">
              <Link to={"/login"}>
                <span className="underline text-indigo-600 text-sm">Indietro</span>
              </Link>
            </Row>
          </form>
        </Card>
      </div>
    </Row>
  )
}

export default Mfa
