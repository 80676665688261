import classNames from "classnames"
import { createElement, FC, HTMLProps } from "react"

export interface Props extends HTMLProps<HTMLElement> {
  as?: keyof JSX.IntrinsicElements
  theme?: "blue" | "green" | "red" | "yellow"
  outline?: boolean
}

const Tag: FC<Props> = ({
  as = "div",
  children,
  className,
  theme = "blue",
  outline,
  ...props
}) => {
  return (
    createElement(
      as,
      {
        className: classNames(
          "tag",
          `tag--${theme}`,
          outline && "tag--outline",
          className
        ),
        ...props
      },
      children
    )
  )
}

export default Tag
