import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import useAuth from "../../hooks/useAuth"
import useError from "../../hooks/useError"
import { CustomerUsersService } from "../../principalsSdk"
import Button from "../Button"
import Col from "../Col"
import InputPassword from "../InputPassword"
import MfaForm from "../MfaForm"

type ChangePasswordForm = {
  oldPassword: string
  newPassword: string
  newPasswordConfirm: string
}

const Security: FC = () => {
  const { formState, handleSubmit, control, getValues } = useForm<ChangePasswordForm>()
  const [loading, setLoading] = useState<boolean>()
  const { user, startSession } = useAuth()
  const { handleError } = useError()
  const [mfaOpen, setMfaOpen] = useState<boolean>(false)

  const onSubmit = async(formData: ChangePasswordForm): Promise<void> => {
    setLoading(true)
    try {
      await CustomerUsersService.changeCustomerUserPassword({
        handler: {
          userId: user?.userId || "",
          customerId: user?.customerId || ""
        },
        newPassword: formData.newPassword,
        oldPassword: formData.oldPassword
      })
      toast.success("Password salvata correttamente")
      const { session } = await CustomerUsersService.customerLogin({
        email: user?.email || "",
        password: formData.newPassword
      })
      await startSession(session)
    } catch (err) {
      handleError(err)
    }
    setLoading(false)
  }

  return (
    <>
      <h2 className="text-2xl font-semibold text-left mb-4">Multi Factor Authentication</h2>
      {mfaOpen || user?.hasMFA
        ? <MfaForm/>
        : <Button onClick={(): void => setMfaOpen(true)}>Attiva la MFA</Button>
      }

      <h2 className="text-2xl font-semibold text-left mb-4 mt-12">Cambia password</h2>

      <form
        className="grid grid-cols-12 lg:gap-x-4 gap-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Col lg={6}>
          <Controller
            name="oldPassword"
            control={control}
            rules={{
              required: "Campo obbligatorio"
            }}
            defaultValue=""
            render={({ field: { onChange, value } }): ReactElement => (
              <InputPassword
                label="Vecchia password"
                value={value}
                onChange={onChange}
                error={formState.errors.oldPassword?.message}
              />
            )}
          />
        </Col>

        <Col className="lg:col-start-1" lg={6}>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: "Campo obbligatorio",
              validate: (val: string): boolean | string => val !== getValues("oldPassword") || "La password nuova deve essere diversa dalla vecchia"
            }}
            defaultValue=""
            render={({ field: { onChange, value } }): ReactElement => (
              <InputPassword
                label="Nuova password"
                value={value}
                onChange={onChange}
                error={formState.errors.newPassword?.message}
              />
            )}
          />
        </Col>

        <Col lg={6}>
          <Controller
            name="newPasswordConfirm"
            control={control}
            rules={{
              required: "Campo obbligatorio",
              validate: (val: string): boolean | string => val === getValues("newPassword") || "Le password non coincidono"
            }}
            defaultValue=""
            render={({ field: { onChange, value } }): ReactElement => (
              <InputPassword
                label="Conferma password"
                value={value}
                onChange={onChange}
                error={formState.errors.newPasswordConfirm?.message}
              />
            )}
          />
        </Col>

        <Col className="mt-2">
          <Button type="submit" className="" disabled={loading}>Salva password</Button>
        </Col>
      </form>
    </>
  )
}

export default Security
