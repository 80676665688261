/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddMessageToTicket200ResponseSchema } from '../models/AddMessageToTicket200ResponseSchema';
import type { AddMessageToTicketRequestSchema } from '../models/AddMessageToTicketRequestSchema';
import type { AssignStaffToTicketRequestSchema } from '../models/AssignStaffToTicketRequestSchema';
import type { CloseTicketRequestSchema } from '../models/CloseTicketRequestSchema';
import type { CreateTicket200ResponseSchema } from '../models/CreateTicket200ResponseSchema';
import type { CreateTicketRequestSchema } from '../models/CreateTicketRequestSchema';
import type { GetAssignedTicketCount200ResponseSchema } from '../models/GetAssignedTicketCount200ResponseSchema';
import type { GetAssignedTicketCountRequestSchema } from '../models/GetAssignedTicketCountRequestSchema';
import type { GetAttachmentUrl200ResponseSchema } from '../models/GetAttachmentUrl200ResponseSchema';
import type { GetAttachmentUrlRequestSchema } from '../models/GetAttachmentUrlRequestSchema';
import type { GetLastUpdateTimestamp200ResponseSchema } from '../models/GetLastUpdateTimestamp200ResponseSchema';
import type { GetLastUpdateTimestampRequestSchema } from '../models/GetLastUpdateTimestampRequestSchema';
import type { GetTicket200ResponseSchema } from '../models/GetTicket200ResponseSchema';
import type { GetTicketRequestSchema } from '../models/GetTicketRequestSchema';
import type { ListTickets200ResponseSchema } from '../models/ListTickets200ResponseSchema';
import type { ListTicketsRequestSchema } from '../models/ListTicketsRequestSchema';
import type { OkSchema } from '../models/OkSchema';
import type { ReopenTicketRequestSchema } from '../models/ReopenTicketRequestSchema';
import type { SwitchTicketStatusRequestSchema } from '../models/SwitchTicketStatusRequestSchema';
import type { UpdateTicketNotesRequestSchema } from '../models/UpdateTicketNotesRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TicketsService {

    /**
     * createTicket
     * Create ticket
     * @param requestBody
     * @returns CreateTicket200ResponseSchema CreateTicket200Response
     * @throws ApiError
     */
    public static createTicket(
        requestBody?: CreateTicketRequestSchema,
    ): CancelablePromise<CreateTicket200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/createTicket',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * listTickets
     * List tickets
     * @param requestBody
     * @returns ListTickets200ResponseSchema ListTickets200Response
     * @throws ApiError
     */
    public static listTickets(
        requestBody?: ListTicketsRequestSchema,
    ): CancelablePromise<ListTickets200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/listTickets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * getTicket
     * Get ticket
     * @param requestBody
     * @returns GetTicket200ResponseSchema GetTicket200Response
     * @throws ApiError
     */
    public static getTicket(
        requestBody?: GetTicketRequestSchema,
    ): CancelablePromise<GetTicket200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/getTicket',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * getAttachmentUrl
     * Get attachment url
     * @param requestBody
     * @returns GetAttachmentUrl200ResponseSchema GetAttachmentUrl200Response
     * @throws ApiError
     */
    public static getAttachmentUrl(
        requestBody?: GetAttachmentUrlRequestSchema,
    ): CancelablePromise<GetAttachmentUrl200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/getAttachmentUrl',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * getAssignedTicketCount
     * Get user assigned ticket count
     * @param requestBody
     * @returns GetAssignedTicketCount200ResponseSchema GetAssignedTicketCount200Response
     * @throws ApiError
     */
    public static getAssignedTicketCount(
        requestBody?: GetAssignedTicketCountRequestSchema,
    ): CancelablePromise<GetAssignedTicketCount200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/getAssignedTicketCount',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * addMessageToTicket
     * Add message to ticket
     * @param requestBody
     * @returns AddMessageToTicket200ResponseSchema AddMessageToTicket200Response
     * @throws ApiError
     */
    public static addMessageToTicket(
        requestBody?: AddMessageToTicketRequestSchema,
    ): CancelablePromise<AddMessageToTicket200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/addMessageToTicket',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * assignStaffToTicket
     * Assign staff to ticket
     * @param requestBody
     * @returns OkSchema AssignStaffToTicket200Response
     * @throws ApiError
     */
    public static assignStaffToTicket(
        requestBody?: AssignStaffToTicketRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/assignStaffToTicket',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * closeTicket
     * Close ticket
     * @param requestBody
     * @returns OkSchema CloseTicket200Response
     * @throws ApiError
     */
    public static closeTicket(
        requestBody?: CloseTicketRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/closeTicket',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * reopenTicket
     * Reopen ticket
     * @param requestBody
     * @returns OkSchema ReopenTicket200Response
     * @throws ApiError
     */
    public static reopenTicket(
        requestBody?: ReopenTicketRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/reopenTicket',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * updateTicketNotes
     * Update ticket notes
     * @param requestBody
     * @returns OkSchema UpdateTicketNotes200Response
     * @throws ApiError
     */
    public static updateTicketNotes(
        requestBody?: UpdateTicketNotesRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/updateTicketNotes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * switchTicketStatus
     * Switch ticket status
     * @param requestBody
     * @returns OkSchema SwitchTicketStatus200Response
     * @throws ApiError
     */
    public static switchTicketStatus(
        requestBody?: SwitchTicketStatusRequestSchema,
    ): CancelablePromise<OkSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/switchTicketStatus',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * getLastUpdateTimestamp
     * Get last update timestamp
     * @param requestBody
     * @returns GetLastUpdateTimestamp200ResponseSchema GetLastUpdateTimestamp200Response
     * @throws ApiError
     */
    public static getLastUpdateTimestamp(
        requestBody?: GetLastUpdateTimestampRequestSchema,
    ): CancelablePromise<GetLastUpdateTimestamp200ResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tickets/getLastUpdateTimestamp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
