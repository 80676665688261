import { FC, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Card from "../components/Card"
import Icon from "../components/Icon"
import Row from "../components/Row"
import Spinner from "../components/Spinner"
import TicketCard from "../components/TicketCard"
import { privateRedirectPath } from "../constants/routes"
import { shorten } from "../helpers/text"
import useAuth from "../hooks/useAuth"
import useError from "../hooks/useError"
import { StaffUser, StaffUsersService } from "../principalsSdk"
import { Ticket, TicketsService } from "../ticketsSdk"

export type StaffUserDict = {
  [id: string]: StaffUser
}

const TicketDetails: FC = () => {
  const { ticketId } = useParams()
  const [ticket, setTicket] = useState<Ticket>()
  const [ticketStaffUsers, setTicketStaffUsers] = useState<StaffUserDict>({})
  // const [supportPlan, setSupportPlan] = useState<SupportPlan | undefined>()
  const { customer } = useAuth()
  const [loading, setLoading] = useState(true)
  const { handleError } = useError()
  const navigate = useNavigate()

  const loadTicket = async(ticketId: string): Promise<Ticket> => {
    const { data: ticket } = await TicketsService.getTicket({
      ticketHandler: {
        customerId: customer?.customerId || "",
        supportPlanId: customer?.supportPlans[0]?.supportPlanId || "",
        ticketId
      }
    })
    if (!ticket.messages?.every(m => ticketStaffUsers[m.author.userId])) {
      const userIds = ticket.messages?.reduce((uniqueStaffIds: string[], m) => {
        if (m.author.customerId) {
          return uniqueStaffIds
        }
        if (!uniqueStaffIds.includes(m.author.userId)) {
          uniqueStaffIds.push(m.author.userId)
        }
        return uniqueStaffIds
      }, []) || []
      if (userIds.length > 0) {
        const { data: staffUsers } = await StaffUsersService.mGetStaffUsers({
          handler: {
            userIds
          }
        })
        const staffUsersDict = staffUsers.reduce((acc: StaffUserDict, u) => {
          acc[u.userId] ??= u
          return acc
        }, {})
        setTicketStaffUsers(staffUsersDict)
      }
    }
    setTicket(ticket)
    return ticket
    // setSupportPlan(customer?.supportPlans?.find((plan) => plan.supportPlanId === ticket.supportPlanId))
  }

  useEffect(() => {
    void (async(): Promise<void> => {
      if (ticketId) {
        setLoading(true)
        try {
          await loadTicket(ticketId)
        } catch (err) {
          handleError(err)
          navigate(privateRedirectPath)
        }
        setLoading(false)
      }
    })()
  }, [ticketId])

  return (
    <Row justify="center">
      <div className="w-full max-w-7xl">
        <div className="col-span-8 mb-3 min-h-10 text-ellipsis">
          <Link to={`${privateRedirectPath}?active=${ticket?.status === "SOLVED" ? "resolved" : "unresolved"}`} className="mr-3">
            <Icon name="arrow-left"/>
          </Link>
          <span className="text-xl sm:text-2xl font-semibold mb-0 relative top-1">{shorten(ticket?.title || "", 425)}</span>
        </div>
        <Card>
          {loading ?
            <Row justify="center" className="mt-5">
              <Spinner/>
            </Row>
            :
            ticket &&
              <>
                <TicketCard
                  ticket={ticket}
                  loadTicket={loadTicket}
                  ticketStaffUsers={ticketStaffUsers}
                />
              </>
          }
        </Card>
      </div>
    </Row>
  )
}

export default TicketDetails
