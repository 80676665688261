import classNames from "classnames"
import { FC, ReactElement, ReactNode, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Row from "./Row"

type Tab = { id: string, name: string, content: ReactNode}

type Props = {
  tabs: Tab[]
  justify?: "around" | "start"
  className?: string
  topRightElement?: ReactElement
}

const findTab = (tabs: Tab[], id: string | null): Tab | undefined => {
  return id
    ? tabs.find(t => t.id === id) || tabs[0]
    : tabs[0]
}

const Tabs: FC<Props> = ({
  tabs,
  justify = "around",
  className,
  topRightElement
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultTabId = searchParams.get("active")
  const defaultTab = findTab(tabs, defaultTabId)
  const [selectedId, _setSelectedId] = useState<string | null>(defaultTab?.id || null)

  const setSelectedId = (id: string | null): void => {
    const tab = findTab(tabs, id)
    if (tab) {
      _setSelectedId(tab.id)
      searchParams.set("active", tab.id)
      setSearchParams(searchParams)
    }
  }

  useEffect(() => {
    if (!searchParams.get("active")) {
      setSelectedId(null)
    }
  }, [searchParams.get("active")])

  return (
    <>
      <div className={classNames("tabs", className)}>
        <Row justify="between">
          <Row className={classNames("nav", justify === "around" && "w-full")} justify={justify}>
            {tabs.map((t, i) =>
              <div
                onClick={(): void => setSelectedId(t.id)}
                key={i}
                className={classNames(
                  t.id === selectedId && "selected",
                  justify === "around" && "w-full",
                  "cursor-pointer"
                )}
              >
                {t.name}
              </div>)}
          </Row>
          {topRightElement &&
            <div className="self-start">
              {topRightElement}
            </div>
          }
        </Row>
        <div className={classNames(
          "outlet",
          justify === "start" && "rounded-r-lg"
        )}>
          {tabs.find(t => t.id === selectedId)?.content}
        </div>
      </div>
    </>
  )
}


export default Tabs