import { Buffer } from "buffer"

export const encodeBase64 = (plain: string): string => {
  const buff = Buffer.from(plain, "utf-8")
  return buff.toString("base64")
}

export const decodeBase64 = (encoded: string): string => {
  const buff = Buffer.from(encoded, "base64")
  return buff.toString("utf-8")
}