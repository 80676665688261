import React, { ReactElement, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import useAuth from "../hooks/useAuth"
import useError from "../hooks/useError"
import { CustomerUsersService } from "../principalsSdk"
import Button from "./Button"
import Col from "./Col"
import InputMfaCode from "./InputMfaCode"
import Row from "./Row"
import Spinner from "./Spinner"

type MFAFormProps = {
  onSubmit?: (code: string) => void
  onCancel?: () => void
}

const MfaForm: React.FunctionComponent<MFAFormProps> = () => {
  const { user, setUser, startSession } = useAuth()

  const [state, setState] = useState<"loading" | "loadingSubmit" | "warning" | "setup" | "done">("loading")
  const [generated, setGenerated] = useState<{ uri: string, qr: string } | null>(null)
  const { handleError } = useError()

  const { formState, handleSubmit, control } = useForm<{ verify: string }>()

  useEffect(() => {
    if (user) {
      setState(user.hasMFA ? "warning" : "loading")
      setGenerated(null)

      if (!user.hasMFA) {
        void generateMFA()
      }
    }
  }, [])

  const generateMFA = async(): Promise<void> => {
    setState("loading")
    try {
      const { data } = await CustomerUsersService.generateCustomerUserMfaSecret({
        handler: {
          userId: user?.userId || "",
          customerId: user?.customerId || ""
        }
      })
      setGenerated(data)
    } catch (error) {
      handleError(error)
    } finally {
      setState("setup")
    }
  }

  const onSubmit = async({ verify: token }: { verify: string }): Promise<void> => {
    setState("loadingSubmit")
    try {
      const { session } = await CustomerUsersService.confirmCustomerUserMfaSecret({
        handler: {
          customerId: user?.customerId || "",
          userId: user?.userId || ""
        },
        token
      })
      await startSession(session)
      if (user) {
        setUser({ ...user, hasMFA: true })
      }
      setState("done")
      toast.success("Multi Factor Authentication attivata correttamente")
    } catch (err) {
      handleError(err)
      setState("setup")
    }
  }

  return (
    <>
      {state === "loading" &&
        <span className={"d-flex justify-content-center my-5"}>
          <Spinner/>
        </span>
      }

      {(state === "warning" || state === "done") && <>
        <p className="text-green-600"><b>La Multi Factor Authentication è attiva sul tuo account</b></p>
        <p>Vuoi effettuare nuovamente l'attivazione? </p>
        <p>Il vecchio generatore smetterà di funzionare appena invierai il codice di verifica del nuovo</p>
        <Button className={"mt-4"} theme="primary" onClick={generateMFA}>Attiva nuovamente la MFA</Button>
      </>}

      {(state === "setup" || state === "loadingSubmit") && !!generated && <>

        <Row className="grid grid-cols-12">
          <Col sm={6} md={4} lg={4} xl={3} xxl={2} className={"self-start"}>

            <img src={generated.qr} alt={"qrcode"} className={"w-10/12 sm:w-full h-auto me-0"}/>

          </Col>
          <Col sm={6} md={8} lg={8} xl={9} xxl={10} className={"self-start sm:ml-4 mt-4 sm:mt-0"}>
            <p><b>Step 1:</b> Scarica Google Authenticator, disponibile per <a className="text-indigo-600" target={"_blank"} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Android</a> e <a className="text-indigo-600" target={"_blank"} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">iOS</a></p>
            <p><b>Step 2:</b> Scannerizza il QR code o copia <a href={generated.uri}>questo URI</a> e salva il generatore di codici</p>
            <p><b>Step 3:</b> Inserisci il codice temporaneo qui sotto</p>
            <form
              className="grid grid-cols-12 lg:gap-x-4 gap-y-2 mt-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col lg={6}>
                <Row>
                  <Controller
                    name="verify"
                    control={control}
                    rules={{
                      required: "Campo obbligatorio"
                    }}
                    defaultValue=""
                    render={({ field: { onChange, value } }): ReactElement => (
                      <InputMfaCode
                        onChange={onChange}
                        label="Verifica Codice"
                        value={value}
                        error={formState.errors.verify?.message}
                        disabled={state === "loadingSubmit"}
                        loading={state === "loadingSubmit"}
                      />
                    )}
                  />
                </Row>
              </Col>
            </form>
          </Col>
        </Row>

      </>}
    </>
  )
}

export default MfaForm