
import classNames from "classnames"
import { FC, ReactNode, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { IconName } from "../../types/icon"
import Card from "../Card"
import Col from "../Col"
import Icon from "../Icon"
import CustomerUsers from "./CustomerUsers"
import PersonalInfo from "./PersonalInfo"
import Security from "./Security"

type SectionId = "general" | "security" | "members"
type Section = {
  id: SectionId
  label: string
  component: ReactNode
  icon: IconName
}
type SectionsMap = {
  [key in SectionId]: Section
}

const sections: Section[] = [
  {
    id: "general",
    label: "Generale",
    component: <PersonalInfo/>,
    icon: "user"
  },
  {
    id: "security",
    label: "Sicurezza",
    component: <Security/>,
    icon: "shield-lock"
  },
  {
    id: "members",
    label: "Utenti",
    component: <CustomerUsers/>,
    icon: "users"
  }
]
const sectionsMap = sections.reduce((sections: SectionsMap, section: Section) => {
  sections[section.id] = section
  return sections
}, {} as SectionsMap)

const AccountTabs: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryActive = searchParams.get("active")
  const [activeSection, _setActiveSection] = useState<SectionId>(
    queryActive
      ? queryActive in sectionsMap
        ? queryActive as SectionId
        : "general"
      : "general"
  )

  const setActiveSection = (section: SectionId): void => {
    _setActiveSection(section)
    searchParams.set("active", section)
    setSearchParams(searchParams)
  }

  const Component = sectionsMap[activeSection].component

  useEffect(() => {
    const queryActive = searchParams.get("active")
    if (queryActive && queryActive in sectionsMap) {
      setActiveSection(queryActive as SectionId)
    }
  }, [searchParams.get("active")])

  return (
    <Card className="account-tabs">
      <div className="grid grid-cols-12">
        <Col lg={3} className="account-tabs__nav">
          <ul className="account-tabs__list">
            {sections.map((section) => (
              <li
                key={section.id}
                className={classNames("account-tabs__item", {
                  "account-tabs__item--active": activeSection === section.id
                })}
                onClick={(): void => setActiveSection(section.id)}
              >
                <Icon name={section.icon} className="account-tabs__item-icon" size={24} />

                <span className="account-tabs__item-label">{section.label}</span>
              </li>
            ))}
          </ul>
        </Col>

        <Col lg={9} className="account-tabs__section">
          {Component}
        </Col>
      </div>
    </Card>
  )
}

export default AccountTabs
