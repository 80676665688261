import classNames from "classnames"
import { BaseSyntheticEvent, FC, HTMLProps } from "react"
import Icon from "./Icon"
import Row from "./Row"
import Tag from "./Tag"

export interface Props extends HTMLProps<HTMLElement> {
  theme?: "blue" | "green" | "red" | "yellow"
  outline?: boolean
  label?: string
  onClose(e: BaseSyntheticEvent): void
}

const Bubble: FC<Props> = ({
  theme = "blue",
  label,
  className,
  onClose,
  children
}) => {
  return (
    <Tag
      outline={true}
      theme={theme}
      className={classNames("bubble", className)}>
      <Row>
        {children}
        {label && <span className="whitespace-nowrap">{ label }</span>}
        <Row onClick={onClose}  className="ml-2 -mr-1">
          <Icon className={classNames(`bubble--${theme}`, "cursor-pointer")} name="x" size={18}/>
        </Row>
      </Row>
    </Tag>
  )
}

export default Bubble
