import { FC } from "react"
import { Link } from "react-router-dom"
import AccountTabs from "../components/account/AccountTabs"
import Icon from "../components/Icon"
import Row from "../components/Row"
import { privateRedirectPath } from "../constants/routes"

const Account: FC = () => {
  return (
    <Row justify="center">
      <div className="w-full max-w-7xl">
        <Row className="mb-3">
          <Link to={privateRedirectPath} className="mr-3">
            <Icon name="arrow-left"/>
          </Link>
          <h1 className="text-3xl font-semibold">Account</h1>
        </Row>
        <AccountTabs/>
      </div>
    </Row>
  )
}

export default Account
