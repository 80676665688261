import { createRef } from "react"
import { ReactElement, ReactNode } from "react"
import { Navigate, useLocation } from "react-router-dom"
import Header from "../../components/header/Header"
import Row from "../../components/Row"
import { SidebarRef } from "../../components/sidebar/Sidebar"
import Spinner from "../../components/Spinner"
import { authRedirectPath } from "../../constants/routes"
import useAuth from "../../hooks/useAuth"

type Props = {
  children: ReactNode
}

export const sidebar = createRef<SidebarRef>()

export default function PrivateWrapper({ children }: Props): ReactElement {
  const { user } = useAuth()
  const location = useLocation()

  return (
    <>
      {user === undefined
        ?
        <Row justify="center" className="w-full h-full">
          <Spinner/>
        </Row>
        : user === null
          ? <Navigate to={authRedirectPath} state={{ from: location }} replace/>
          :
          <div className="private">
            {/* <Sidebar ref={sidebar}/> */}

            <div className="private__content">
              <Header />

              <main className="private__main">
                {children}
              </main>
            </div>
          </div>
      }
    </>
  )
}
