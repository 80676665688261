import { FC } from "react"
import useAuth from "../../hooks/useAuth"
import Icon from "../Icon"
import Row from "../Row"


const PersonalInfo: FC = () => {
  const { user, principalsCustomer } = useAuth()

  return (
    <>
      <h2 className="text-2xl font-semibold text-left ml-1 mb-4">Informazioni personali</h2>

      { user?.username &&
      <Row justify="start" className="mb-2">
        <Icon name={"user"} className="mr-4"/><span className="text-lg">{user.username}</span>
      </Row>
      }
      { user?.email &&
      <Row justify="start" className="mb-2">
        <Icon name={"at"} className="mr-4"/><span className="text-lg">{user.email}</span>
      </Row>
      }
      { principalsCustomer?.customerName &&
      <Row justify="start" className="mb-2">
        <Icon name={"briefcase"} className="mr-4"/><span className="text-lg">{principalsCustomer.customerName}</span>
      </Row>
      }
    </>
  )
}

export default PersonalInfo