import { FC, ReactNode } from "react"
import AuthProvider from "./AuthContext"

type Props = {
  children: ReactNode
}

const Providers: FC<Props> = ({ children }: Props) => {
  return (
    <AuthProvider>
      {children}
    </AuthProvider>
  )
}

export default Providers
