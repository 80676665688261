import classNames from "classnames"
import { FC, ReactNode } from "react"
import { Link } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import Row from "../Row"
import Table from "../Table"


const CustomerUsers: FC = () => {
  const { sameCustomerUsers, user, principalsCustomer } = useAuth()

  const visibleCustomerUsers = Object.values(sameCustomerUsers).filter(u => u.active)
  return (
    <>
      { principalsCustomer?.customerName &&
        <h2 className="text-2xl font-semibold text-left ml-1 mb-4">Utenti di {principalsCustomer?.customerName}</h2>
      }
      { visibleCustomerUsers.length > 0 ?
        <div className="w-full overflow-x-auto">
          <Table
            className={classNames("table")}
            headings={["Username", "Email", "Protetto con MFA"]}
            fixedColumns={[]}
            leftColumns={["Username", "Email", "Protetto con MFA"]}
            rows={visibleCustomerUsers.map(({
              userId, username, email, hasMFA
            }): { id: string, children: ReactNode[] } => {
              return {
                id: userId,
                children: [
                  <span>{username}</span>,
                  <span>{email}</span>,
                  <span>{hasMFA
                    ? "Protetto"
                    : <>
                      Non protetto {userId === user?.userId ? <> - <Link to={"/account?active=security"}><span className="underline text-indigo-600">Attiva MFA</span></Link></> : ""}
                    </>}
                  </span>
                ]
              }
            })}
          />
        </div>
        :
        <Row justify="center" className="mt-5">
          <h1>Nessun ticket presente</h1>
        </Row>
      }

    </>
  )
}

export default CustomerUsers