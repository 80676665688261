import classNames from "classnames"
import { FC, ReactNode, ReactElement } from "react"
import Row from "./Row"
import { SetState } from "../types/ui"

type Props = {
  headings: string[]
  fixedColumns?: string[]
  leftColumns?: string[]
  rows: { id: string, children: ReactNode[] }[]
  selectable?: boolean
  selectedItems?: Set<string>
  setSelectedItems?: SetState<Set<string>>
  className?: string
}

const Table: FC<Props> = ({
  headings,
  fixedColumns = [],
  leftColumns = [],
  rows,
  selectable = false,
  selectedItems,
  setSelectedItems,
  className
}) => {
  return (

    <table className={classNames("table", className)}>
      <thead>
        <tr>
          {
            selectable && !!selectedItems && !!setSelectedItems &&
              <th className={"table__column w-px"}>
                <Row align={"center"}>
                  <label className={"inline-flex"}>
                    <span className={"sr-only"}>Select all</span>
                    <input
                      className={"form-checkbox"}
                      type={"checkbox"}
                      disabled={!rows.length}
                      checked={selectedItems.size === rows.length && !!rows.length}
                      onChange={(event): void => {
                        if (event.target.checked) {
                          setSelectedItems(new Set(rows.map(({ id }) => id)))
                        } else {
                          setSelectedItems(new Set())
                        }
                      }}
                    />
                  </label>
                </Row>
              </th>
          }
          {
            headings.map((heading, i) => (
              <th key={`heading-${i}`} className={classNames({ "w-px": fixedColumns.includes(heading), "text-left": leftColumns.includes(heading) }, "border-b")}>{heading}</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map(({ id: rowId, children }, i) =>
            <tr key={`row-${i}`}>
              {
                selectable && !!selectedItems && !!setSelectedItems &&
                  <td>
                    <Row>
                      <label className={"inline-flex"}>
                        <span className={"sr-only"}></span>
                        <input
                          id={rowId}
                          onChange={(event): void => {
                            setSelectedItems(selectedItems => {
                              if (event.target.checked) {
                                selectedItems.add(rowId)
                              } else {
                                selectedItems.delete(rowId)
                              }

                              return new Set(selectedItems)
                            })
                          }}
                          checked={selectedItems.has(rowId)}
                          className={"form-checkbox"}
                          type={"checkbox"}
                        />
                      </label>
                    </Row>
                  </td>
              }
              {
                children.map((field, j) => {
                  return (
                    <td key={`field-${j}`} className={classNames(((field as ReactElement)?.type !== "button" && "border-b"), "py-2.5")}>
                      {field}
                    </td>
                  )
                })
              }
            </tr>)
        }
      </tbody>
    </table>
  )
}


export default Table